import React, { useState } from "react";

import Dropdown from "./Dropdown";

export default {
  title: "Dropdown",
  component: Dropdown,
};

// Dropdown components need a bit of extra height in order to render their children
// without cropping. This just gives them a bit of extra space to expand.
const Wrapper = ({ children }) => <div style={{ height: "600px" }}>{children}</div>;

const Template = (args) => {
  const [selected, setSelected] = useState("Harry");
  const handleItemSelect = (item) => setSelected(item);
  const stateProps = { handleItemSelect, selected, id: "dropdown-sb-template" };
  const fullArgs = { ...args, ...stateProps };
  return (
    <Wrapper>
      <Dropdown {...fullArgs} />
    </Wrapper>
  );
};

export const DropdownDefault = Template.bind({});
DropdownDefault.args = {
  items: ["Harry", "Hermione", "Ron"],
};
