import classNames from "classnames";
import React from "react";
import { ArticleBlockData } from "./types";

function ArticleBlock({ title, subtitle, articles, className }: ArticleBlockData) {
  return (
    <section className={classNames("article-block", "shaded-block", className)}>
      <div>
        <h2>{title}</h2>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <ul>
        {articles.slice(0, 4).map((article) => (
          <li key={article.id}>
            <div>
              <a href={article.url} target="_blank" rel="noreferrer">
                <img {...article.featuredImage} />
                {article.title}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ArticleBlock;
