import PropTypes from "prop-types";
import AccountNavBar from "../AccountNavBar";
import AgencyNav from "./AgencyNav";
import UpdatesTable from "./UpdatesTable";

export default function AgenciesChangeHistory({ updates = [] }) {
  return (
    <div className="agencies-container row">
      <div className="agencies-content">
        <AccountNavBar selection="agency" />
        <div className="agencies-row">
          <AgencyNav selection="changeHistory" />
          <div className="agencies-data-container">
            <UpdatesTable updates={updates} sortByResource={true} />
          </div>
        </div>
      </div>
    </div>
  );
}

AgenciesChangeHistory.propTypes = {
  updates: PropTypes.array,
};
