import React, { useEffect, useState, FC } from "react";
import CalendarBlock from "../TopicPages/CalendarBlock";
import { CalendarBlockData, UpcomingEvent } from "../TopicPages/types";

interface Props {
  description: string;
  calendarLink: string;
}

export default function HomeCalendar({ description, calendarLink }) {
  const [events, setEvents] = useState<UpcomingEvent[]>([]);
  useEffect(() => {
    const date = new Date();
    fetch("/events")
      .then((response) => response.json())
      .then((data) => setEvents(data["events"] as unknown as UpcomingEvent[]));
  }, []);
  const calendarData: CalendarBlockData = {
    id: 1,
    type: "CalendarBlock",
    position: 1,
    description: "View upcoming community events",
    events,
    calendarLink: "https://uwc.211ct.org/events/",
  };
  return <CalendarBlock {...calendarData} />;
}
