import React from "react";

export default function List() {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      stroke="currentColor"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        strokeWidth={0.2}
        d="M1.5 1.75002C1.91421 1.75002 2.25 1.41423 2.25 1.00002C2.25 0.585802 1.91421 0.250015 1.5 0.250015C1.08579 0.250015 0.75 0.585802 0.75 1.00002C0.75 1.41423 1.08579 1.75002 1.5 1.75002ZM4 1.00002C4 0.723873 4.22386 0.500015 4.5 0.500015H13.5C13.7761 0.500015 14 0.723873 14 1.00002C14 1.27616 13.7761 1.50002 13.5 1.50002H4.5C4.22386 1.50002 4 1.27616 4 1.00002ZM4.5 3.50002C4.22386 3.50002 4 3.72387 4 4.00002C4 4.27616 4.22386 4.50002 4.5 4.50002H13.5C13.7761 4.50002 14 4.27616 14 4.00002C14 3.72387 13.7761 3.50002 13.5 3.50002H4.5ZM4.5 6.50002C4.22386 6.50002 4 6.72387 4 7.00002C4 7.27616 4.22386 7.50002 4.5 7.50002H13.5C13.7761 7.50002 14 7.27616 14 7.00002C14 6.72387 13.7761 6.50002 13.5 6.50002H4.5ZM2.25 4.00002C2.25 4.41423 1.91421 4.75002 1.5 4.75002C1.08579 4.75002 0.75 4.41423 0.75 4.00002C0.75 3.5858 1.08579 3.25002 1.5 3.25002C1.91421 3.25002 2.25 3.5858 2.25 4.00002ZM1.5 7.75002C1.91421 7.75002 2.25 7.41423 2.25 7.00002C2.25 6.5858 1.91421 6.25002 1.5 6.25002C1.08579 6.25002 0.75 6.5858 0.75 7.00002C0.75 7.41423 1.08579 7.75002 1.5 7.75002Z"
      />
    </svg>
  );
}
