import AccountNavBar from "../AccountNavBar";

const AgencyPending = () => (
  <div className="agencies-container row">
    <div className="agencies-content">
      <AccountNavBar selection="agency" />
      <div className="agencies-row">
        <div>
          <h1 className="agencies-title">Agency Information</h1>
          <div className="agency-note">
            Your account is currently pending approval by our Resource Specialists. Once approved, you will be notified
            by email and will then be able to manage your agency data. If you have not received a response in 48 hours,
            please contact Customer Support.
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AgencyPending;
