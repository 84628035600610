import PropTypes from "prop-types";
import createReactClass from "create-react-class";

const ResponseNote = createReactClass({
  propTypes: {
    text: PropTypes.string,
    responseId: PropTypes.number,
    changeNote: PropTypes.func,
  },

  handleOnChange: function (event) {
    const { responseId, changeNote } = this.props;
    changeNote({ responseId: responseId, note: event.target.value });
  },

  render: function () {
    const { text, responseId } = this.props;
    const idName = `response-${responseId}-note`;
    const textValue = text || "";

    return (
      <div className="response-note-area">
        <textarea
          id={idName}
          className="response-note"
          value={textValue}
          placeholder="Remind yourself of something..."
          onChange={this.handleOnChange}
        />
      </div>
    );
  },
});

export default ResponseNote;
