import React from "react";
import PropTypes from "prop-types";
import ReactUtils from "./../../react-utils";
import { AgencyParams } from "./agency-params";

export default function UpdatesRows({ updates }) {
  const getRowSubcontent = (type, requestedChanges, relationshipChanges) => {
    if (relationshipChanges.length) {
      const fieldName = Object.keys(relationshipChanges[0])[0];
      return `Relationship change: ${fieldName.charAt(0).toUpperCase()}${fieldName.split("_").join(" ").slice(1)}`;
    } else if (Object.keys(requestedChanges).length) {
      const fieldsChanged = Object.keys(requestedChanges).map((attr_name) => {
        const methodName = `get${type.charAt(0).toUpperCase() + type.slice(1)}Params`;
        const updateParams = AgencyParams[methodName]({
          crisperEnrolled: true,
        });

        const field = attr_name;
        let label = (updateParams[field] && updateParams[field].title) || null;

        if (field.includes("mailing")) {
          const subfield = updateParams.mailingAddress.subfields[field];
          label = subfield ? subfield.title : field;
        } else if (
          field.includes("address") ||
          field.includes("street") ||
          field.includes("city") ||
          field.includes("state") ||
          field.includes("postal_code") ||
          field.includes("county")
        ) {
          const subfield = updateParams.address.subfields[field];
          label = subfield ? subfield.title : field;
        }

        return label;
      });

      return `Fields changed: ${fieldsChanged.join(", ")}`;
    }
  };

  return (
    <React.Fragment>
      {updates.map(
        ({ id, type, createdAt, address1, name, requestStatus, requestedChanges, relationshipChanges }, idx) => (
          <tr key={idx}>
            <td>
              <div className="agency-resource-column">
                <div className="agency-resource">
                  <a href={`/my211/updates/${type}_updates/${id}`} className="small-label">
                    {`View ${type
                      .split("_")
                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(" ")} Change`}
                  </a>
                </div>
                <div className="agency-details-container">
                  <div className="agency-details">
                    <div>{ReactUtils.formatTimestamp(createdAt)}</div>
                    <div>{address1 ? address1 : name}</div>
                    <div className={`emphasis ${requestStatus}-text`}>
                      {`${requestStatus.charAt(0).toUpperCase()}${requestStatus.slice(1)}`}
                    </div>
                  </div>
                  <div className="agency-row-subcontent">
                    {getRowSubcontent(type, requestedChanges, relationshipChanges)}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )
      )}
    </React.Fragment>
  );
}

UpdatesRows.propTypes = {
  updates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.oneOf(["agency", "site", "service", "service_site_relationship"]).isRequired,
      createdAt: PropTypes.string.isRequired,
      address1: PropTypes.string,
      name: PropTypes.string,
      requestStatus: PropTypes.string.isRequired,
      requestedChanges: PropTypes.object.isRequired,
      relationshipChanges: PropTypes.array.isRequired,
    })
  ).isRequired,
};
