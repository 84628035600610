import PropTypes from "prop-types";

export default function AgencyNav(props) {
  const { selection } = props;

  return (
    <div className="agencies-sidenav-container">
      <div className={`sidenav-my211-btn ${selection === "agencyInformation" && "selected"}`}>
        <a className="my211-nav-btn" href="/my211/agency_data">
          Agency Information
        </a>
      </div>
      <div className={`sidenav-my211-btn ${selection === "sites" && "selected"}`}>
        <a className="my211-nav-btn" href="/my211/agency_sites">
          Sites
        </a>
      </div>
      <div className={`sidenav-my211-btn ${selection === "services" && "selected"}`}>
        <a className="my211-nav-btn" href="/my211/agency_services">
          Services
        </a>
      </div>
      <div className={`sidenav-my211-btn ${selection === "changeHistory" && "selected"}`}>
        <a className="my211-nav-btn" href="/my211/updates">
          Change History
        </a>
      </div>
    </div>
  );
}

AgencyNav.propTypes = {
  selection: PropTypes.string.isRequired,
};
