import PropTypes from "prop-types";
import React from "react";
import AccountNavBar from "../AccountNavBar";
import AgencyNav from "./AgencyNav";
import AgencyData from "./AgencyData";
import UpdatesTable from "./UpdatesTable";

export default function AgenciesData({ agencies, updates = [] }) {
  return (
    <div className="agencies-container row">
      <div className="agencies-content">
        <AccountNavBar selection="agency" />
        <div className="agencies-row">
          <AgencyNav selection="agencyInformation" />
          <div className="agencies-data-container">
            <h1 className="agencies-title">Agency Information</h1>
            <div className="agency-note">
              Please note that changes to your agency information won’t appear immediately &mdash; a Resource Specialist
              will review and approve or reach out to you with any questions.
            </div>
            {agencies.map((agency) => (
              <AgencyData key={agency.number} {...agency} />
            ))}
            <UpdatesTable updates={updates} />
          </div>
        </div>
      </div>
    </div>
  );
}

AgenciesData.propTypes = {
  agencies: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
    })
  ),
  updates: PropTypes.array,
};
