import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropdown from "../Dropdown";
import Checkboxes from "../Checkboxes";

const Input = ({
  type,
  placeholder,
  name,
  picklist = [],
  singleOption = null,
  splitChar = /[,;|]/,
  setFieldsState,
  fieldsState,
  errorFields,
  validateField,
}) => {
  switch (type) {
    case "textarea":
      return (
        <textarea
          className="input gradient-background agency-change-input"
          placeholder={placeholder}
          value={fieldsState[name] || ""}
          onChange={(e) =>
            setFieldsState({
              ...fieldsState,
              [name]: e.target.value || null,
            })
          }
        />
      );
    case "richtext":
      return (
        <React.Fragment>
          <ReactQuill
            id={name}
            className={`input gradient-background agency-change-input ${
              errorFields && errorFields.has(name) && "agency-error-input"
            }`}
            placeholder={placeholder}
            value={fieldsState[name] || ""}
            onChange={(content) => {
              validateField(name, content);
              return setFieldsState({
                ...fieldsState,
                [name]: content || null,
              });
            }}
          />
          {errorFields && errorFields.has(name) && <div className="agency-error-message">{placeholder}</div>}
        </React.Fragment>
      );
    case "text":
      return (
        <React.Fragment>
          <input
            id={name}
            type="text"
            className={`input gradient-background agency-change-input ${
              errorFields && errorFields.has(name) && "agency-error-input"
            }`}
            placeholder={placeholder}
            value={fieldsState[name] || ""}
            onChange={(e) => {
              validateField(name, e.target.value);
              return setFieldsState({
                ...fieldsState,
                [name]: e.target.value || null,
              });
            }}
          />
          {errorFields && errorFields.has(name) && <div className="agency-error-message">{placeholder}</div>}
        </React.Fragment>
      );
    case "boolean":
      return (
        <div className="agency-change-input">
          <Dropdown
            items={["Yes", "No"]}
            handleItemSelect={(selection) =>
              setFieldsState({
                ...fieldsState,
                [name]: selection === "Yes" ? true : selection === "No" ? false : null,
              })
            }
            id={name}
            selected={fieldsState[name] === true ? "Yes" : fieldsState[name] === false ? "No" : "Select"}
          />
        </div>
      );
    case "select":
      return (
        <div className="agency-change-input">
          <Dropdown
            items={picklist}
            handleItemSelect={(selection) =>
              setFieldsState({
                ...fieldsState,
                [name]: selection === "Select" ? null : selection,
              })
            }
            id={name}
            selected={fieldsState[name] || "Select"}
          />
        </div>
      );
    case "checkbox":
      return (
        <Checkboxes
          items={picklist}
          handleItemClick={(itemClicked) => {
            let newValue = fieldsState[name] ? fieldsState[name].split(splitChar).map((f) => f.trim()) : [];

            if (newValue.includes(itemClicked)) newValue.splice(newValue.indexOf(itemClicked), 1);
            else if (itemClicked === singleOption) newValue = [itemClicked];
            else {
              newValue.push(itemClicked);

              if (newValue.includes(singleOption)) newValue.splice(newValue.indexOf(singleOption), 1);
            }

            return setFieldsState({
              ...fieldsState,
              [name]: newValue
                .sort()
                .filter((elt) => elt !== "")
                .join(`|`),
            });
          }}
          selected={fieldsState[name] ? fieldsState[name].split(splitChar).map((f) => f.trim()) : []}
        />
      );
  }
};

export default Input;
