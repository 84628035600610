const apiService = (() => {
  function customFetch(url, method, body) {
    return new Promise((resolve, reject) => {
      const tokenMetaTag = document.querySelector("meta[name=csrf-token]");
      const token = tokenMetaTag && tokenMetaTag.content;

      fetch(url, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": token,
          "Content-Type": "application/json",
        },
        method,
        body: JSON.stringify(body),
      }).then((response) => {
        if (method === "DELETE") return resolve();

        response.json().then((responseBody) => {
          if (!response.ok) reject(responseBody);
          resolve(responseBody);
        });
      });
    });
  }

  return {
    get: (url) => customFetch(url, "GET"),
    post: (url, body) => customFetch(url, "POST", body),
    put: (url, body) => customFetch(url, "PUT", body),
    delete: (url) => customFetch(url, "DELETE"),
  };
})();

export { apiService };
