import React from "react";
import PropTypes from "prop-types";
import ReactUtils from "./../../react-utils";
import AccountNavBar from "../AccountNavBar";
import Dropdown from "../Dropdown";
import ReferralItem from "./ReferralItem";

const { useState } = React;

export default function ReferralList(props) {
  const [referrals, setReferrals] = useState(props.referrals);
  const [filterStatus, setFilterStatus] = useState(/.*/g);
  const currentReferrals = referrals.filter((referral) => referral.status.match(filterStatus));

  const sortBy = (sorter) => {
    setReferrals(ReactUtils.sortBy(sorter, [...referrals]));
  };

  const filterBy = (status) => {
    status === "all" ? setFilterStatus(/.*/g) : setFilterStatus(status);
  };

  const sortItems = [
    { "Most recent": "created_at-desc" },
    { "Oldest first": "created_at" },
    { "Name - A-Z": "for_name" },
    { "Name - Z-A": "for_name-desc" },
    { "Agency - A-Z": "agency_name" },
    { "Agency - Z-A": "agency_name-desc" },
    { "Program - A-Z": "program_name" },
    { "Program - Z-A": "program_name-desc" },
  ];
  const [sortSelectedItem, setSortSelectedItem] = useState(Object.keys(sortItems[0])[0]);

  const statusItems = [
    { "View all": "all" },
    { Accepted: "accepted" },
    { Declined: "declined" },
    { Pending: "pending" },
  ];
  const [statusSelectedItem, setStatusSelectedItem] = useState(Object.keys(statusItems[0])[0]);

  return (
    <div className="referrals-container row">
      <div className="referrals-content">
        <AccountNavBar selection="referrals" />
        <div className="table-row table-header-row referrals-row">
          <div className="referrals-title">
            <h1 className="lists-index">
              {currentReferrals.length || "No"} Service Request
              {currentReferrals.length != 1 && "s"}
            </h1>
          </div>
          <div className="referrals-row dropdowns-container">
            <div className="dropdown-container">
              <div className="dropdown-label">Sort:</div>
              <Dropdown
                items={sortItems}
                handleItemSelect={(item) => {
                  const name = Object.keys(item)[0];
                  setSortSelectedItem(name);
                  sortBy(item[name]);
                }}
                id="sort"
                selected={sortSelectedItem}
              />
            </div>
            <div className="dropdown-container">
              <div className="dropdown-label">Status:</div>
              <Dropdown
                items={statusItems}
                handleItemSelect={(item) => {
                  const name = Object.keys(item)[0];
                  setStatusSelectedItem(name);
                  filterBy(item[name]);
                }}
                id="status"
                selected={statusSelectedItem}
              />
            </div>
          </div>
        </div>
        <div className="table-container">
          {0 == currentReferrals.length && (
            <p>
              Some resources accept requests for services directly from the 211 search engine. You can find them by
              using the advanced filter for 'Accepts online service requests'
            </p>
          )}
          <table>
            <tbody>
              {currentReferrals.map((r) => (
                <ReferralItem referral={r} key={r.uuid} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

ReferralList.propTypes = {
  referrals: PropTypes.array.isRequired,
};
