import React from "react";
import PropTypes from "prop-types";

export default function Checkboxes({ items, handleItemClick, selected }) {
  return (
    <React.Fragment>
      {items.map((item) => (
        <div className="checkbox-option" key={item}>
          <label>
            <input type="checkbox" checked={selected.includes(item)} onChange={() => handleItemClick(item)} id={item} />
            {item}
          </label>
        </div>
      ))}
    </React.Fragment>
  );
}

Checkboxes.propTypes = {
  items: PropTypes.array.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
};
