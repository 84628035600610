import PropTypes from "prop-types";

export default function Dropdown({ items, handleItemSelect, id, selected, rightAlign }) {
  return (
    <div className="dropdown">
      <button
        className="dropdown-toggle dropdown-toggle-btn"
        type="button"
        id={id}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {selected}
        <div className="caret-icon" />
      </button>
      <ul className={`dropdown-menu ${rightAlign && "dropdown-menu-right"}`} aria-labelledby={id}>
        {items.map((item, index) => {
          const name = typeof item === "object" ? Object.keys(item)[0] : item;
          return (
            <li key={index}>
              <button
                className={`dropdown-item dropdown-item-btn${selected === name ? "-selected" : ""}`}
                onClick={() => handleItemSelect(item)}
                type="button"
              >
                {name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  handleItemSelect: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  rightAlign: PropTypes.bool,
};
