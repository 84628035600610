import React from "react";

import type { Image } from "./types";
import SearchIcon from "../../icons/Search";
import ListIcon from "../../icons/List";
import ReactUtils from '../../../javascript/react-utils';
import { ServiceLocale } from "../types";

interface Props {
  title: string;
  description: string;
  heroImage: Image;
  serviceLocale: ServiceLocale;
}

function Hero({ title, description, heroImage, serviceLocale }) {
  const locationInputEl = React.useRef(null);

  React.useEffect(() => {
    new google.maps.places.Autocomplete(
      locationInputEl.current,
      { componentRestrictions: { country: "US" } }
    );
  }, []);

  React.useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const location = urlSearchParams.get('location');
    locationInputEl.current.value = location;
  }, []);

  const handleSearch = async (e) => {
    e.preventDefault();
    const { value: address } = locationInputEl.current;

    ReactUtils.geocode(address, serviceLocale, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (_.isEmpty(results)) {
          console.log("Geocoder failure", results, status);
        } else {
          const position = results[0].geometry.location;
          const coords = { lng: position.lng(), lat: position.lat() };
          const { lat, lng } = coords;
          if (!lat || !lng) {
            window.document.location.href = `?location=${encodeURI(address)}`;
          } else {
            ReactUtils.getServiceArea(results, (serviceArea) => {
              window.document.location.href = `?location=${encodeURI(address)}&lat=${lat ? lat : ''}&lng=${lng ? lng : ''}&service_area=${serviceArea ? serviceArea.toLowerCase() : ''}`;
            });
          }
        }
      }
    });
  };

  return (
    <header>
      <div className="hero">
        <div>
          <div>
            <h1 className="topic-page-title">{title}</h1>
            <p className="topic-page-description">{description}</p>
          </div>
        </div>
        <img {...heroImage} />
      </div>
      <div className="header-floor">
        <form onSubmit={handleSearch} autoComplete='off'>
          <label>My Location</label>
          <div>
            <input 
              ref={locationInputEl}
              autoComplete="off"
              placeholder="Search address, city or zip" 
              type="text" 
              aria-label="input search location" />
            <button type="submit">
              <SearchIcon />
            </button>
          </div>
        </form>
        <a href="/my211/resource-lists" target="_blank" rel="noreferrer">
          <ListIcon /> My Resource Lists
        </a>
      </div>
    </header>
  );
}

export default Hero;
