import React from "react";
import PropTypes from "prop-types";
import ChangeField from "./ChangeField";

const { useState } = React;

export default function AgencyChange({ type, id, fieldParams }) {
  const [fieldsState, setFieldsState] = useState({});
  const [changeCount, setChangeCount] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [invalidInputs, setInvalidInputs] = useState(false);

  React.useEffect(() => {
    // formatUpdateData() outputs _old and _new changes so we need to divide by 2
    setChangeCount(Object.values(formatUpdateData()).length / 2);
  }, [fieldsState]);

  const formatUpdateData = () => {
    let data = {};
    let initialValue;
    let currentValue;

    Object.keys(fieldsState).map((field) => {
      initialValue = (fieldParams[field] && fieldParams[field].initialValue) || null;
      currentValue = fieldsState[field] || null;
      if (fieldParams[field] && currentValue !== initialValue && fieldsState[field] !== undefined) {
        data[field + "_old"] = initialValue;
        data[field + "_new"] = currentValue;
      } else {
        Object.values(fieldParams).map((field) => {
          if (field.subfields) {
            Object.keys(field.subfields).map((subfield) => {
              initialValue = field.subfields[subfield].initialValue || null;
              currentValue = fieldsState[subfield] || null;
              if (currentValue !== initialValue && fieldsState[subfield] !== undefined) {
                data[subfield + "_old"] = initialValue;
                data[subfield + "_new"] = currentValue;
              }
            });
          }
        });
      }
    });
    return data;
  };

  const buildUpdateData = () => {
    let newData = { [type === "service" ? "program_id" : `${type}_id`]: id };

    const data = formatUpdateData();
    newData = { ...newData, ...data };

    return newData;
  };

  const submitChanges = () => {
    setSubmitting(true);
    $.ajax(`/${type}_updates`, {
      method: "post",
      data: JSON.stringify(buildUpdateData()),
      dataType: "json",
      contentType: "application/json",
    })
      .done((response) => {
        window.location = `/my211/updates/${type}_updates/${response.id}`;
      })
      .fail((e) => console.error(e));
  };

  return (
    <div className="agency-change-container">
      {Object.keys(fieldParams).map((field) => {
        const initialFieldValues = {};
        initialFieldValues[field] = fieldParams[field];
        return (
          <ChangeField
            key={field}
            fieldName={field}
            initialFieldValues={initialFieldValues}
            setFieldsState={setFieldsState}
            fieldsState={fieldsState}
            submitting={submitting}
            setInvalidInputs={setInvalidInputs}
          />
        );
      })}
      <div className="agency-fixed-submit-btn-container">
        <button
          className={`btn btn-tall ${!!changeCount && "btn-primary"}`}
          onClick={() => submitChanges()}
          name="Submit"
          disabled={!changeCount || submitting || invalidInputs}
        >
          Submit Changes
          {!!changeCount && !invalidInputs && ` (${changeCount})`}
        </button>
      </div>
    </div>
  );
}

AgencyChange.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  fieldParams: PropTypes.object.isRequired,
};
