import React from "react";
import PropTypes from "prop-types";
import ReactUtils from "./../react-utils";
import ResourceSection from "./ResourceSection";
import ResourceListContainer from "./ResourceListContainer";
import createReactClass from "create-react-class";

const SurveySummaryContainer = createReactClass({
  propTypes: {
    responseSet: PropTypes.string,
    signedIn: PropTypes.bool,
    canClaim: PropTypes.bool,
    savedDate: PropTypes.string,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    serviceLocale: PropTypes.shape({
      abbr: PropTypes.string,
      stateName: PropTypes.string,
    }),
  },

  urlRoot: "/search",
  defaults: {
    page: 1,
    perPage: 25,
    serviceArea: "connecticut",
    defaultLocation: "Connecticut",
  },

  nameFieldInput: React.createRef(),

  getInitialState: function () {
    const responseSet = JSON.parse(this.props.responseSet);
    const { location } = responseSet;
    const groupedAnswers = _.values(_.groupBy(responseSet.responses, (response) => response.question_id));
    const responses = groupedAnswers.map((qa) => {
      const taxonomyCodes = _.uniq(
        _.flatten(
          qa.map((a) =>
            a.answer.search_params && a.answer.search_params.taxonomy_code ? a.answer.search_params.taxonomy_code : []
          )
        )
      );
      return {
        question: qa[0].question.text,
        category: qa[0].question.category,
        order: qa[0].question.order,
        type: qa[0].question.type,
        helpUrl: qa[0].question.help_url,
        answers: qa.map((a) => a.answer.text),
        taxonomies: taxonomyCodes
          .map((taxonomyCode) => responseSet.search_params.taxonomies.find((t) => t.code === taxonomyCode))
          .filter((t) => t),
        personalInfo: qa[0].response_personal_info,
        responseId: qa[0].id,
      };
    });
    const filteredResponses = _.reject(responses, (r) => _.isEmpty(r.taxonomies));
    return {
      location: location || this.defaults.defaultLocation,
      ageRange: responseSet.search_params.age_range,
      referral: responseSet.search_params.referral,
      token: responseSet.token,
      canClaim: this.props.canClaim,
      savedDate: this.props.savedDate,
      hasUnsavedChanges: false,
      responses: _.sortBy(filteredResponses, "order"),
      surveyName: responseSet.name,
      token: responseSet.token,
      name: responseSet.name,
      newName: responseSet.name,
      renaming: false,
      renamingError: null,
      isModalDisplayed: false,
      user: this.props.user,
    };
  },

  componentDidMount: function () {
    ReactUtils.geocode(this.state.location, this.props.serviceLocale, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (_.isEmpty(results)) {
          console.log("Geocoder failure", results, status);
        } else {
          const position = results[0].geometry.location;
          const coords = { lng: position.lng(), lat: position.lat() };
          ReactUtils.getServiceArea(results, (service_area) => {
            this.setState({ coords, serviceArea: service_area.toLowerCase() });
          });
        }
      }
    });
  },

  toggleModalState: function () {
    this.setState({ isModalDisplayed: !this.state.isModalDisplayed });
    const overflow = document.body.style.overflow;
    document.body.style.overflow = overflow === "hidden" ? "visible" : "hidden";
  },

  getAllTaxonomies: function () {
    const allTaxonomies = _.flatten(this.state.responses.map((response) => response.taxonomies));
    return _.uniq(allTaxonomies, (taxonomy) => taxonomy.id);
  },

  getCategory: function (responseId) {
    const thisResponse = this.state.responses.find((r) => r.responseId === responseId);
    return thisResponse ? thisResponse.category : null;
  },

  onSearch: function (taxonomies, responseId, resourceList) {
    if (resourceList && resourceList.hashid) {
      sessionStorage.setItem("resource_list_id", resourceList.hashid);
    } else {
      sessionStorage.removeItem("resource_list_id");
    }

    const ids = taxonomies.map((t) => t.id);
    const queryParams = {
      page: this.defaults.page,
      per_page: this.defaults.perPage,
      location: this.state.location,
      service_area: this.state.serviceArea,
      defaultLocation: this.defaults.defaultLocation,
      age_range: this.state.ageRange,
      referral: this.state.referral,
      showFilters: false,
      coords: this.state.coords,
      taxonomy_code: ids,
      response_id: responseId,
      category: this.getCategory(responseId),
      survey_name: this.state.name,
      token: this.state.token,
    };
    window.location.href = `${this.urlRoot}?${ReactUtils.toUrlVars(queryParams)}`;
  },

  saveResponseSet: function () {
    $.ajax(`/response_sets/${this.state.token}/claim`, {
      method: "patch",
    }).done((data) => {
      this.setState({ savedDate: data.updated_at, canClaim: false });
    });
  },

  saveResponseInfo: function () {
    const { name } = this.state;
    const statuses = this.state.responses.reduce(
      (acc, curr) =>
        curr.personalInfo
          ? acc.concat({
              status: curr.personalInfo.status,
              note: curr.personalInfo.note,
              id: curr.responseId,
            })
          : acc,
      []
    );
    $.ajax(`/response_sets/${this.state.token}`, {
      method: "patch",
      data: JSON.stringify({
        response_set: { name: name, responses: statuses },
      }),
      dataType: "json",
      contentType: "application/json",
    }).done((data) => {
      this.setState({
        savedDate: data.updated_at,
        hasUnsavedChanges: false,
        renaming: false,
      });
    });
  },

  removeCategory: function (taxonomyCode) {
    const newResponses = this.state.responses.map((response) => ({
      ...response,
      taxonomies: _.reject(response.taxonomies, (taxonomy) => taxonomy.code === taxonomyCode),
    }));
    this.setState({ responses: newResponses });
  },

  changeProgressStatus: function ({ responseId, newStatus }) {
    const newResponses = this.state.responses.map((response) => ({
      ...response,
      personalInfo:
        response.responseId === responseId ? { ...response.personalInfo, status: newStatus } : response.personalInfo,
    }));
    this.setState({ responses: newResponses, hasUnsavedChanges: true });
  },

  getStatus: function (responseId) {
    const response = _.find(this.state.responses, (response) => response.responseId === responseId);
    return response.personalInfo ? response.personalInfo.status : undefined;
  },

  changeNote: function ({ responseId, note }) {
    const newResponses = this.state.responses.map((response) => ({
      ...response,
      personalInfo:
        response.responseId === responseId ? { ...response.personalInfo, note: note } : response.personalInfo,
    }));
    this.setState({ responses: newResponses, hasUnsavedChanges: true });
  },

  changeName: function (e) {
    const name = e.target.value;
    this.setState({
      newName: name,
      renamingError: name.length > 0 ? null : this.state.renamingError,
    });
  },

  onNameFieldBlur: function () {
    const trimmedName = this.state.newName.trim();
    if (trimmedName.length < 1) {
      this.setState({ renamingError: "Name cannot be blank" });
      this.nameFieldInput.current.focus();
    } else {
      this.setState({
        name: trimmedName,
        newName: trimmedName,
        hasUnsavedChanges: true,
        renamingError: null,
      });
    }
  },

  toggleRenaming: function () {
    this.setState({ renaming: true });
  },

  renderSaveButton() {
    const disabled = !this.state.hasUnsavedChanges || this.state.renamingError;
    let saveButtonStyle = "btn btn-secondary pointer";
    saveButtonStyle = disabled ? `${saveButtonStyle} disabled` : saveButtonStyle;
    if (!this.props.signedIn) {
      return (
        <a
          className="btn btn-secondary pointer save-screener"
          href={`/response_sets/${this.state.token}/deferred_claim`}
        >
          Save Responses To Your Account
        </a>
      );
    } else if (this.state.savedDate) {
      return (
        <React.Fragment>
          <a onClick={() => this.saveResponseInfo()} className={saveButtonStyle}>
            Save Changes
          </a>
          <div className="screener-saved">Saved on {this.state.savedDate}</div>
        </React.Fragment>
      );
    } else if (this.state.canClaim) {
      return (
        <a onClick={() => this.saveResponseSet()} className={saveButtonStyle}>
          Save Responses To Your Account
        </a>
      );
    }
  },

  renderBreadcrumb() {
    const responseSet = JSON.parse(this.props.responseSet);
    const { name, newName, renaming, renamingError } = this.state;

    let nameSection;

    if (renaming) {
      nameSection = (
        <input value={newName} onChange={this.changeName} onBlur={this.onNameFieldBlur} ref={this.nameFieldInput} />
      );
    } else {
      nameSection = <a href={`/assessments/response_sets/${responseSet.token}`}>{name}</a>;
    }

    return (
      <div>
        <ol className="breadcrumb top">
          <li>
            <a href="/my211/screeners">Screeners</a>
          </li>
          <li className="active">{nameSection}</li>
          {!renaming && (
            <li className="no-arrow">
              <a href="#" className="rename-screener-link" onClick={this.toggleRenaming}>
                Rename
              </a>
            </li>
          )}
        </ol>
        {renamingError && <div className="renaming-error">{renamingError}</div>}
      </div>
    );
  },

  render: function () {
    const { token, isModalDisplayed, user, name } = this.state;
    const csrfParam = $('meta[name="csrf-param"]').attr("content");
    const csrfToken = $('meta[name="csrf-token"]').attr("content");
    const allTaxonomies = this.getAllTaxonomies();
    const { signedIn } = this.props;
    const surveyFormHeaderClassName = signedIn ? "survey-form-header" : "survey-form-header right";

    return (
      <div className="row survey-container">
        <div className="survey-content">
          <div className="summary">
            <div>
              <div className="survey-form">
                <div className={surveyFormHeaderClassName}>
                  {signedIn && this.renderBreadcrumb()}
                  <div className="save-screener top">{this.renderSaveButton()}</div>
                </div>
                <p>Thank you for completing the screener!</p>
                <p>
                  Based on your responses, we think you may benefit from these resources. Remove search terms that don't
                  meet your needs by clicking on the X. Add resources to any specific topic using the green Add
                  Resources button. If you are logged into your My 211 Account, you will have the option to mark the
                  status of a resource as well as to type in reminder notes. Use the PDF option on the top right corner
                  to download a summary.
                </p>

                <form className="pdf-download-row" action="/response_sets/export.pdf" method="post">
                  <input type="hidden" name="token" value={token} />
                  <input type="hidden" name={csrfParam} value={csrfToken} />
                  <input type="submit" value="Download PDF of this Summary" className="summary-pdf-download" />
                </form>
                <hr className="category-separator" />
                <div className="answers">
                  {this.state.responses.map((questionAnswer, index) => (
                    <ResourceSection
                      category={questionAnswer.category}
                      key={index}
                      status={this.getStatus(questionAnswer.responseId)}
                      changeProgressStatus={this.changeProgressStatus}
                      hasTaxonomies={questionAnswer.taxonomies.length > 0}
                      signedIn={this.props.signedIn}
                      responseId={questionAnswer.responseId}
                    >
                      <ResourceListContainer
                        key={index}
                        questionAnswer={questionAnswer}
                        removeCategory={this.removeCategory}
                        onSearch={this.onSearch}
                        signedIn={signedIn}
                        changeNote={this.changeNote}
                        toggleModalState={this.toggleModalState}
                        isModalDisplayed={isModalDisplayed}
                        user={user}
                        surveyName={name}
                        category={questionAnswer.category}
                        token={token}
                      />
                    </ResourceSection>
                  ))}
                  <a onClick={() => this.onSearch(allTaxonomies)} className="btn btn-default pointer search-all">
                    Search for all categories ({allTaxonomies.length})
                  </a>
                  <div className="save-screener">{this.renderSaveButton()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalDisplayed && <div className="modal-backdrop fade in"></div>}
      </div>
    );
  },
});

export default SurveySummaryContainer;
