import React from "react";
import type { UW211OrgIdentifier } from "../types";
import COFooter from "./COFooter";
import CTFooter from "./CTFooter";

interface Props {
  uw211Identifier: UW211OrgIdentifier;
}

function Footer({ uw211Identifier }: Props) {
  switch (uw211Identifier) {
    case "CT":
      return <CTFooter />;
    case "CO":
      return <COFooter />;
    default:
      return <></>;
  }
}

export default Footer;
