import createReactClass from "create-react-class";
import PropTypes from "prop-types";

const Chat = createReactClass({
  propTypes: {
    chatAvailable: PropTypes.bool,
    organization: PropTypes.object,
  },

  onClick: function () {
    if (
      this.props.organization.flags &&
      this.props.organization.flags.enableChat &&
      this.props.chatAvailable &&
      this.props.organization.links &&
      this.props.organization.links.chat
    ) {
      window.open(
        this.props.organization.links.chat,
        "2-1-1 Chat",
        "'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,top=200,left=500,width=500,height=650'"
      );
    }
  },

  render: function () {
    const { chatAvailable, organization } = this.props;
    return (
      <a className="nav-link" onClick={this.onClick}>
        <i style={{ marginRight: "10px" }} className="ico ico-icon-chat" />
        {organization.flags &&
        organization.flags.enableChat &&
        chatAvailable &&
        organization.links &&
        organization.links.chat ? (
          <span className="chat-status">Chat With Us</span>
        ) : (
          <span className="chat-status">Chat Unavailable</span>
        )}
      </a>
    );
  },
});

export default Chat;
