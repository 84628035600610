import PropTypes from "prop-types";
import AgencyChange from "./AgencyChange";
import { AgencyParams } from "./agency-params";

const AgencyData = (agency) => (
  <AgencyChange type="agency" id={agency.number} fieldParams={AgencyParams.getAgencyParams(agency)} />
);

export default AgencyData;

AgencyData.propTypes = {
  agency: PropTypes.shape({
    number: PropTypes.number,
    name: PropTypes.string,
    street1: PropTypes.string,
    street2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    mailingStreet1: PropTypes.string,
    mailingStreet2: PropTypes.string,
    mailingCity: PropTypes.string,
    mailingState: PropTypes.string,
    mailingPostalCode: PropTypes.string,
    phone: PropTypes.string,
    description: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    organizationType: PropTypes.string,
    hoursOfOperation: PropTypes.string,
    agencyAliases: PropTypes.string,
    county: PropTypes.string,
    directorName: PropTypes.string,
    directorPosition: PropTypes.string,
    updateContactName: PropTypes.string,
    updateContactEmail: PropTypes.string,
    updateContactPhone: PropTypes.string,
  }),
};
