import React from "react";
import PropTypes from "prop-types";

export default class ScreenerListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      name: props.responseSet.name,
      formName: props.responseSet.name,
    };

    this.toggleForm = this.toggleForm.bind(this);
    this.changeName = this.changeName.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  toggleForm() {
    const { editing } = this.state;

    this.setState({ editing: !editing });
  }

  changeName(e) {
    this.setState({ formName: e.target.value });
  }

  submitForm(e) {
    const { responseSet } = this.props;
    const { token } = responseSet;
    const { formName } = this.state;

    e.preventDefault();
    e.stopPropagation();

    $.ajax(`/response_sets/${token}`, {
      method: "patch",
      data: JSON.stringify({ response_set: { name: formName } }),
      dataType: "json",
      contentType: "application/json",
    }).done((data) => {
      this.setState({ name: formName, editing: false });
    });
  }

  render() {
    const { responseSet } = this.props;
    const { token, created_at } = responseSet;
    const { editing, formName, name } = this.state;

    let details = <h4 className="screener-name">{name}</h4>;

    if (editing) {
      details = (
        <form className="resource-list-form" onSubmit={this.submitForm}>
          <div className="input-error">
            <input className="input" value={formName} name="Name" onChange={this.changeName} />
            <input
              className="resource-list-name-update btn btn-primary"
              disabled={_.isEmpty(formName)}
              type="submit"
              value="Update"
            />
          </div>
        </form>
      );
    }

    return (
      <div className="search-result card-highlight">
        <div className="actions">
          <div className="action small-label">
            <a
              href={`/response_sets/${token}/unclaim`}
              data-confirm="Are you sure you want to remove this screener?"
              data-method="patch"
            >
              <i className="glyphicon glyphicon-trash" />
              Delete
            </a>
          </div>
          <div className="action small-label">
            <a href="#" onClick={this.toggleForm}>
              <i className="glyphicon glyphicon-pencil" />
              Rename
            </a>
          </div>
        </div>
        <div className="resource-list-details-container">
          <div className="resource-list-details">
            {details}
            {`Created on ${created_at}`}
          </div>
        </div>
        <a className="btn btn-primary more" href={`/assessments/response_sets/${token}`}>
          Open Screener
        </a>
      </div>
    );
  }
}

ScreenerListItem.propTypes = {
  responseSet: PropTypes.shape({
    token: PropTypes.string,
    name: PropTypes.string,
    created_at: PropTypes.string,
  }),
};
