import React from "react";
import LoadingScreen from "./LoadingScreen";
import AccountNavBar from "./AccountNavBar";
import CreateResourceList from "./CreateResourceList";
import ResourceLists from "./ResourceLists";
import { ResourceListsContext } from "./../contexts/ResourceListsContext";
import { AppDataContext } from "./../contexts/AppDataContext";

export default function ResourceListsLayout() {
  const { resourceLists, listsLoading } = React.useContext(ResourceListsContext);
  const { appPaths } = React.useContext(AppDataContext);

  const [sortBy, setSortBy] = React.useState("date");

  return (
    <div className="resource-lists-container">
      <div className="resource-list-content">
        {listsLoading ? (
          <LoadingScreen />
        ) : (
          <React.Fragment>
            <AccountNavBar selection="resources" appPaths={appPaths} />

            <div>
              <div className="resource-lists-title">
                <h1 className="lists-index">
                  <span>{resourceLists.length} Resource Lists</span>
                </h1>
              </div>

              <div className="order-by">
                Order by:&nbsp;
                <a
                  role="button"
                  className={`btn btn-default btn-xs${(sortBy === "date" && " active") || ""}`}
                  onClick={() => setSortBy("date")}
                >
                  Date created
                </a>
                &nbsp;
                <a
                  role="button"
                  className={`btn btn-default btn-xs${(sortBy === "name" && " active") || ""}`}
                  onClick={() => setSortBy("name")}
                >
                  Name
                </a>
              </div>
            </div>

            <CreateResourceList />
            <ResourceLists lists={resourceLists} sortBy={sortBy} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
