import React from "react";
import PropTypes from "prop-types";

export default class SendReferralModal extends React.Component {
  constructor(props) {
    super(props);

    const firstName = props.user && props.user.first_name ? props.user.first_name.trim() : "";
    const lastName = props.user && props.user.last_name ? props.user.last_name.trim() : "";

    this.state = {
      name: `${firstName} ${lastName}`.trim(),
      email: props.user && props.user.email ? props.user.email : "",
      phone: props.user && props.user.phone ? props.user.phone : "",
      message: `Hello, are you accepting clients for ${props.programName} at this moment? Thank you.`,
      emailSent: false,
      validEmail: true,
      validPhone: true,
    };
    this.handleSendEmail = this.handleSendEmail.bind(this);
  }

  handleOnChange(field) {
    return function (e) {
      const value = e.target.value;
      this.setState({ [field]: value });

      if (field === "email") this.setState({ validEmail: true });
      if (field === "phone") this.setState({ validPhone: true });
    }.bind(this);
  }

  handleSendEmail(e) {
    e.preventDefault();
    const { email, phone, name, message } = this.state;
    const payload = {
      for_email: email,
      for_phone: phone,
      for_name: name,
      message,
      program_site_id: this.props.programSiteId,
    };

    $.ajax("/referral_emails", {
      method: "post",
      data: JSON.stringify(payload),
      dataType: "json",
      contentType: "application/json",
    }).then((response) => {
      this.setState({ emailSent: true });
    });
  }

  validate(field) {
    if (field === "email") {
      const { email } = this.state;
      if (email && !App.Util.containsAnEmailAddress(email)) {
        this.setState({ validEmail: false });
      } else this.setState({ validEmail: true });
    }

    if (field === "phone") {
      const { phone } = this.state;
      if (phone && !App.Util.isValidPhone(phone)) {
        this.setState({ validPhone: false });
      } else this.setState({ validPhone: true });
    }
  }

  render() {
    const { programSiteId, programName, agencyName, toggleModalState } = this.props;
    const { name, email, phone, message, emailSent, validEmail, validPhone } = this.state;

    return (
      <div className="modal-open">
        <div
          className="modal fade send-referral in"
          role="dialog"
          tabIndex="-1"
          style={{ zIndex: 9999, display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content referral-modal-content">
              <button
                type="button"
                aria-label="close"
                className="referral-ico cancel-icon"
                onClick={toggleModalState}
              />
              {!emailSent && (
                <div className="modal-header">
                  <h2 className="referral-modal-header dark-blue-text">Request Services</h2>
                  <p className="referral-modal-sub-header">{agencyName}</p>
                </div>
              )}
              <div className="content modal-body referral-input-box">
                {emailSent ? (
                  <div className="success-message">
                    <h4 className="label dark-blue-text">Service Request Sent</h4>
                    <h3 className="dark-blue-text referral-header">
                      You will be notified by email when the agency responds.
                    </h3>
                    <p className="referral-confirmation">You can view all your service requests in your account.</p>
                    <a id="referral-confirmation-button" className="btn btn-default" href="/my211/referrals">
                      View your service requests
                    </a>
                  </div>
                ) : (
                  <form className="referral-form">
                    <input
                      className="input name large-height gradient-background"
                      name="name"
                      placeholder="Full Name"
                      value={name}
                      onChange={this.handleOnChange("name")}
                    />
                    <input
                      id={`email${!validEmail && "-error-box"}`}
                      className="email input large-height gradient-background"
                      name="email"
                      placeholder="E-mail"
                      value={email}
                      onChange={this.handleOnChange("email")}
                      onBlur={() => this.validate("email")}
                    />
                    {!validEmail && <div className="input-error-text">Invalid E-mail</div>}
                    <input
                      id={`phone${!validPhone && "-error-box"}`}
                      className="input phone large-height gradient-background"
                      name="phone"
                      placeholder="Phone (optional)"
                      value={phone}
                      onChange={this.handleOnChange("phone")}
                      onBlur={() => this.validate("phone")}
                    />
                    {!validPhone && <div className="input-error-text">Invalid Phone</div>}
                    <label>Add a Note (optional)</label>
                    <textarea
                      className="input message gradient-background"
                      name="message"
                      placeholder="Message"
                      rows="5"
                      wrap="hard"
                      value={message}
                      onChange={this.handleOnChange("message")}
                    ></textarea>
                    <button
                      className={`btn btn-primary more ${!(email && name) && "referral-disabled-button"}`}
                      onClick={this.handleSendEmail}
                      name="send"
                      disabled={!(email && name && validEmail && validPhone)}
                    >
                      Request Services
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SendReferralModal.propTypes = {
  user: PropTypes.object,
  programSiteId: PropTypes.number.isRequired,
  programName: PropTypes.string.isRequired,
  agencyName: PropTypes.string.isRequired,
  toggleModalState: PropTypes.func.isRequired,
};
