import React from "react";

export default function Search() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6667 8.66667C13.6667 11.4281 11.4281 13.6667 8.66666 13.6667C5.90523 13.6667 3.66666 11.4281 3.66666 8.66667C3.66666 5.90525 5.90523 3.66667 8.66666 3.66667C11.4281 3.66667 13.6667 5.90525 13.6667 8.66667ZM12.5411 13.2482C11.4957 14.1331 10.1435 14.6667 8.66666 14.6667C5.35295 14.6667 2.66666 11.9804 2.66666 8.66667C2.66666 5.35296 5.35295 2.66667 8.66666 2.66667C11.9804 2.66667 14.6667 5.35296 14.6667 8.66667C14.6667 10.1435 14.1331 11.4958 13.2482 12.5411L17.0202 16.3131C17.2155 16.5084 17.2155 16.825 17.0202 17.0202C16.8249 17.2155 16.5084 17.2155 16.3131 17.0202L12.5411 13.2482Z"
        fill="white"
      />
    </svg>
  );
}
