import classNames from "classnames";
import React from "react";
import ToggleButton from "../ToggleButton";
import ProgramAtSiteCard from "./ProgramAtSiteCard";
import { SubTopicBlockData } from "./types";

const MAX_PROGRAMS = 4;

function SubTopicBlock({ title, description, taxonomies, className, serviceLocale }: SubTopicBlockData) {
  const [activeTaxonomyCode, setActiveTaxonomyCode] = React.useState(taxonomies[0]?.code);

  const handleTaxonomyClick = (taxonomyCode: string) => () => {
    setActiveTaxonomyCode(taxonomyCode);
  };

  const activeTaxonomy = taxonomies.find(({ code }) => code === activeTaxonomyCode);
  const programs = (activeTaxonomy?.programs || []).slice(0, MAX_PROGRAMS);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const location = urlSearchParams.get("location") || serviceLocale.stateName.toLowerCase();
  const serviceArea = urlSearchParams.get("service_area") || serviceLocale.stateName.toLowerCase();

  return (
    <section className={classNames("section sub-topic-block", className)}>
      <h2 className="sub-topic-block-title">{title}</h2>
      {description && <p className="sub-topic-block-description">{description}</p>}
      <div className="taxonomies">
        {taxonomies.map((taxonomy, idx) => (
          <ToggleButton
            key={taxonomy.code + idx}
            className="taxonomy"
            toggled={taxonomy.code === activeTaxonomyCode}
            onClick={handleTaxonomyClick(taxonomy.code)}
          >
            {taxonomy.name}
          </ToggleButton>
        ))}
      </div>
      <div className="programs">
        {programs.length == 0 ? (
          <div>No programs found.</div>
        ) : (
          programs.map((program, idx) => <ProgramAtSiteCard key={program.number + idx} {...program} />)
        )}
      </div>
      <a
        href={
          `/search?` +
          "page=1&" +
          `taxonomy_code=${encodeURIComponent(taxonomies.map((t) => t.id).join(","))}&` +
          `location=${encodeURIComponent(location)}&` +
          `service_area=${encodeURIComponent(serviceArea)}`
        }
        className="view-all-resources"
      >
        View all or refine your search &rarr;
      </a>
    </section>
  );
}
export default SubTopicBlock;
