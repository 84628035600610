import PropTypes from "prop-types";

export default function ReferralThankYou(props) {
  const { for_name, from_name } = props.names;

  return (
    <div className="referrals-container row" id="referral-response-confirmation">
      <div className="referrals-content">
        <div className="table-container referral-container">
          <div className="referrals-title center-text">Thank you!</div>
          <div className="referral-detail center-text">
            Your response has been sent. {for_name}
            {from_name && ` and ${from_name}`} will be notified by email and through{" "}
            <a href="https://www.211ct.org/">211ct.org</a>.
          </div>
        </div>
      </div>
    </div>
  );
}

ReferralThankYou.propTypes = {
  names: PropTypes.shape({
    for_name: PropTypes.string.isRequired,
    from_name: PropTypes.string,
  }),
};
