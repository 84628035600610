import React from "react";
import PropTypes from "prop-types";
import Chat from "./Chat";
import createReactClass from "create-react-class";

const MobileNav = createReactClass({
  propTypes: {
    homePath: PropTypes.string,
    accountPath: PropTypes.string,
    signOutPath: PropTypes.string,
    authenticationPath: PropTypes.string,
    registrationPath: PropTypes.string,
    user: PropTypes.string,
    isAgencyUser: PropTypes.bool,
    chatAvailable: PropTypes.bool,
    resourceListsPath: PropTypes.string,
    mobileNavVisible: PropTypes.bool,
    myReferralsPath: PropTypes.string,
    myAgencyDataPath: PropTypes.string,
    organization: PropTypes.object,
  },

  render: function () {
    const {
      homePath,
      accountPath,
      signOutPath,
      authenticationPath,
      registrationPath,
      user,
      isAgencyUser,
      chatAvailable,
      resourceListsPath,
      mobileNavVisible,
      myReferralsPath,
      myAgencyDataPath,
      organization,
    } = this.props;

    return (
      <React.Fragment>
        <div className="mobile-nav-image"></div>
        <ul className={mobileNavVisible ? "nav-list animated fadeInUp" : "nav-list"}>
          {Object.keys(organization.navbar).map((title, i) => {
            if (typeof organization.navbar[title] === "object") {
              return (
                <React.Fragment key={i}>
                  {organization.navbar[title].href && (
                    <li className="nav-item">
                      <a className="nav-link" href={organization.navbar[title].href}>
                        {title}
                      </a>
                    </li>
                  )}
                  {Object.keys(organization.navbar[title].items).map((subtitle, i) => {
                    return (
                      <li className="nav-item" key={i}>
                        <a className="nav-link sub-nav-link" href={organization.navbar[title].items[subtitle]}>
                          {subtitle}
                        </a>
                      </li>
                    );
                  })}
                </React.Fragment>
              );
            } else {
              return (
                <li className="nav-item" key={i}>
                  <a className="nav-link" href={organization.navbar[title]}>
                    {title}
                  </a>
                </li>
              );
            }
          })}
          <hr />
          {organization.flags && organization.flags.enableChat && (
            <React.Fragment>
              <li className="chat-nav nav-item">
                <Chat chatAvailable={chatAvailable} organization={organization} />
              </li>
              <hr />
            </React.Fragment>
          )}
          {user && (
            <React.Fragment>
              <li className="nav-item">
                <a className="nav-link" href={accountPath}>
                  My 211 Account
                </a>
              </li>
              {isAgencyUser && (
                <li className="nav-item">
                  <a className="nav-link" href={myAgencyDataPath}>
                    Agency
                  </a>
                </li>
              )}
              {organization.userFeatures && organization.userFeatures.screeners && (
                <li className="nav-item">
                  <a className="nav-link" href="/my211/screeners">
                    Screeners
                  </a>
                </li>
              )}
              <li className="nav-item">
                <a className="nav-link" href={resourceListsPath}>
                  Resource Lists
                </a>
              </li>
              {organization.userFeatures && organization.userFeatures.referrals && (
                <li className="nav-item">
                  <a className="nav-link" href={myReferralsPath}>
                    Referrals
                  </a>
                </li>
              )}
              <hr />
              <li className="nav-item nav-item-action">
                <a className="nav-link nav-action" href={signOutPath} data-method="delete">
                  Log out
                </a>
              </li>
            </React.Fragment>
          )}
          {!user && (
            <React.Fragment>
              <li className="nav-item nav-item-action">
                <a className="nav-link border-nav-item nav-action" href={registrationPath}>
                  Sign up
                </a>
              </li>
              <li className="nav-item nav-item-action">
                <a className="nav-link nav-action" href={authenticationPath}>
                  Login
                </a>
              </li>
            </React.Fragment>
          )}
        </ul>
      </React.Fragment>
    );
  },
});

export default MobileNav;
