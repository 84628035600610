export const StateAgencyUtils = {
  ar: {
    organizationTypeList: [
      "Coalition",
      "Commercial",
      "Cooperative",
      "Education",
      "Faith-based",
      "Government - City",
      "Government - State",
      "Government - County",
      "Incorporated",
      "Library",
      "Membership",
      "Museum",
      "None or Unknown",
      "Nonprofit",
      "Nonprofit - Incorporated",
      "Nonprofit - Unincorporated",
      "Private 501C3",
      "Private Non-Profit",
      "School District",
    ],
    countiesList: [
      "Arkansas",
      "Ashley",
      "Baxter",
      "Benton",
      "Boone",
      "Bradley",
      "Calhoun",
      "Carroll",
      "Chicot",
      "Clark",
      "Clay",
      "Cleburne",
      "Cleveland",
      "Columbia",
      "Conway",
      "Craighead",
      "Crawford",
      "Crittenden",
      "Cross",
      "Dallas",
      "Desha",
      "Drew",
      "Faulkner",
      "Franklin",
      "Fulton",
      "Garland",
      "Grant",
      "Greene",
      "Hempstead",
      "Hot Spring",
      "Howard",
      "Independence",
      "Izard",
      "Jackson",
      "Jefferson",
      "Johnson",
      "Lafayette",
      "Lawrence",
      "Lee",
      "Lincoln",
      "Little River",
      "Logan",
      "Lonoke",
      "Madison",
      "Marion",
      "Miller",
      "Mississippi",
      "Monroe",
      "Montgomery",
      "Nevada",
      "Newton",
      "Ouachita",
      "Perry",
      "Phillips",
      "Pike",
      "Poinsett",
      "Polk",
      "Pope",
      "Prairie",
      "Pulaski",
      "Randolph",
      "Saint Francis",
      "Saline",
      "Scott",
      "Searcy",
      "Sebastian",
      "Sevier",
      "Sharp",
      "Stone",
      "Union",
      "Van Buren",
      "Washington",
      "White",
      "Woodruff",
      "Yell",
    ],
  },
  ct: {
    organizationTypeList: [
      "Coalition",
      "Commercial",
      "Faith-based",
      "Government - City",
      "Government - State",
      "Government - Town",
      "Membership",
      "None or Unknown",
      "Nonprofit - Incorporated",
      "Nonprofit - Unincorporated",
      "Voluntary Association",
    ],
    disabilitiesAccessList: [
      "ADA Compliant",
      "Handicap accessible bathrooms",
      "Handicap parking available",
      "Ramps",
      "Wheelchair accessible",
      "Braille in elevators",
    ],
  },
  co: {
    countiesList: [
      "Adams County",
      "Arapahoe County",
      "Boulder County",
      "Denver County",
      "Jefferson County",
      "Weld County",
      "Alamosa County",
      "Archuleta County",
      "Baca County",
      "Bent County",
      "Broomfield County",
      "Chaffee County",
      "Cheyenne County",
      "Clear Creek County",
      "Conejos County",
      "Costilla County",
      "Crowley County",
      "Custer County",
      "Delta County",
      "Dolores County",
      "Douglas County",
      "Eagle County",
      "El Paso County",
      "Elbert County",
      "Fremont County",
      "Garfield County",
      "Gilpin County",
      "Grand County",
      "Gunnison County",
      "Hinsdale County",
      "Huerfano County",
      "Jackson County",
      "Kiowa County",
      "Kit Carson County",
      "La Plata County",
      "Lake County",
      "Larimer County",
      "Las Animas County",
      "Lincoln County",
      "Logan County",
      "Mesa County",
      "Mineral County",
      "Moffat County",
      "Montezuma County",
      "Montrose County",
      "Morgan County",
      "Otero County",
      "Ouray County",
      "Park County",
      "Phillips County",
      "Pitkin County",
      "Prowers County",
      "Pueblo County",
      "Rio Blanco County",
      "Rio Grande County",
      "Routt County",
      "Saguache County",
      "San Juan County",
      "San Miguel County",
      "Sedgwick County",
      "Summit County",
      "Teller County",
      "Washington County",
      "Yuma County",
      "Out of State ",
    ],
  },
};

export const AgencyUtils = {
  MultiField: class MultiField {
    constructor(title, subfields, description = null) {
      this.title = title;
      this.subfields = subfields;
      this.description = description;
    }
  },
  Field: class Field {
    constructor({
      title,
      initialValue,
      description = null,
      placeholder = null, // Placeholder of the field input
      inputType = "text",
      picklist = [], // List of options used in a Dropdown or Checkboxes
      singleOption = null, // Option that disregards others in the picklist
      splitChar = /[,;|]/, // Character to split initialValue string for Dropdowns or Checkboxes
    }) {
      this.title = title;
      this.initialValue = initialValue;
      this.description = description;
      this.placeholder = placeholder;
      this.inputType = inputType;
      this.picklist = picklist;
      this.singleOption = singleOption;
      this.splitChar = splitChar;

      if (inputType === "checkbox") {
        this.initialValue = !initialValue ? "" : initialValue.split(splitChar).sort().join(splitChar);
      }

      if (initialValue === true) this.initialValue = "Yes";
      if (initialValue === false) this.initialValue = "No";

      if (picklist.length) {
        this.initialValue = picklist.includes(this.initialValue) ? this.initialValue : null;
      }
    }
  },
  datePlaceholder: "mm/dd/yyyy",
  phonePlaceholder: "(123) 456-7890",
  emailPlaceholder: "email@example.com",
  organizationTypeList: ["Commercial", "Educational", "Faith-based", "Government", "Nonprofit", "Other"],
  genderList: ["Female", "Male", "Transgender", "Non Binary", "No Restrictions"],
  wheelchairAccessList: ["Yes", "No", "Limited, call for specific information", "Not applicable"],
  disabilitiesAccessList: ["Accessible Bathroom", "Braille Signage", "Designated Parking", "Elevator", "Ramp"],
  insuranceList: [
    "Medicaid",
    "Medicare",
    "Veterans Benefits",
    "Private Insurance",
    "CHP+",
    "Other Insurance",
    "Not Applicable",
  ],
  statusList: ["Active", "Inactive"],
  countiesList: [],
  statesList: [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ],
  languagesList: [
    "American Sign Language",
    "Amharic",
    "Arabic",
    "Bassa",
    "Bengali",
    "Burmese",
    "Cherokee (Kituwah Dialect)",
    "Chinese",
    "Creole",
    "Croatian",
    "English",
    "Farsi",
    "French",
    "German",
    "Greek",
    "Haitian",
    "Hebrew",
    "Hindi",
    "Hmong",
    "Indonesian Interpretation Services",
    "Interpretation Available",
    "Italian",
    "Japanese",
    "Karen",
    "Kayah",
    "Khmer",
    "Korean",
    "Kunama",
    "Lakota",
    "Lingala",
    "Lisu",
    "Malay",
    "Malayalam",
    "Mandarin",
    "Mandinka",
    "Navajo",
    "Nepali",
    "Oromo",
    "Pidgin Signed English",
    "Polish Portuguese",
    "Romanian",
    "Russian",
    "Sangu",
    "Shoshonean",
    "Somali",
    "Spanish",
    "Swahili",
    "Swedish",
    "Tagalog",
    "Tamil",
    "Tigranya",
    "Urdu",
    "Ute",
    "Vietnamese",
    "Yiddish",
  ],
};
