import PropTypes from "prop-types";
import AccountNavBar from "../AccountNavBar";
import AgencyNav from "./AgencyNav";
import AgencyChange from "./AgencyChange";
import RelationList from "./RelationList";
import UpdatesTable from "./UpdatesTable";
import { AgencyParams } from "./agency-params";

export default function AgencyService({ service, sitesNotForService, updates = [] }) {
  const { number, name } = service;

  const fieldParams = AgencyParams.getServiceParams(service);

  return (
    <div className="agencies-container row">
      <div className="agencies-content">
        <AccountNavBar selection="agency" />
        <div className="agencies-row">
          <AgencyNav selection="services" />
          <div className="agencies-data-container">
            <div>
              <ol className="breadcrumb top breadcrumb-new">
                <li>
                  <a href="/my211/agency_services">All Services</a>
                </li>
                <li className="active">{name || number}</li>
              </ol>
            </div>
            <AgencyChange type="service" id={number} fieldParams={fieldParams} />
            <RelationList id={number} listType="sites" addRelationList={sitesNotForService} />
            <UpdatesTable updates={updates} />
          </div>
        </div>
      </div>
    </div>
  );
}

AgencyService.propTypes = {
  service: PropTypes.shape({
    number: PropTypes.number.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    languages: PropTypes.string,
    programHoursOfOperation: PropTypes.string,
    mainEmailAddress: PropTypes.string,
    website: PropTypes.string,
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    phone3: PropTypes.string,
    phoneHotline: PropTypes.string,
    phoneFax: PropTypes.string,
    applicationProcess: PropTypes.string,
    eligibility: PropTypes.string,
    documentsRequired: PropTypes.string,
    customGender: PropTypes.string,
    customMaximumAge: PropTypes.number,
    customMinimumAge: PropTypes.number,
    feeStructureSource: PropTypes.string,
    alternateName: PropTypes.string,
    coverage: PropTypes.string,
    contactName: PropTypes.string,
    contactTitle: PropTypes.string,
    contactEmail: PropTypes.string,
    tips: PropTypes.string,
    serviceStatus: PropTypes.string,
    phoneAfterHours: PropTypes.string,
    waitList: PropTypes.string,
    immigrantsWithoutDocumentation: PropTypes.bool,
    serviceFees: PropTypes.bool,
    insuranceAccepted: PropTypes.string,
    paymentNotes: PropTypes.string,
    displayStartDate: PropTypes.string,
    displayEndDate: PropTypes.string,
  }),
  sitesNotForService: PropTypes.array,
  updates: PropTypes.array,
};
