import React from "react";
import PropTypes from "prop-types";
import Progress from "./Progress";
import createReactClass from "create-react-class";

const ResourceSection = createReactClass({
  propTypes: {
    category: PropTypes.string,
    children: PropTypes.element.isRequired,
    responseId: PropTypes.number,
    status: PropTypes.string,
    changeProgressStatus: PropTypes.func,
    hasTaxonomies: PropTypes.bool,
    signedIn: PropTypes.bool,
  },

  getInitialState: function () {
    return { isCollapsed: false };
  },

  toggleCollapsed: function () {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  },

  render: function () {
    const { category, children, responseId, status, changeProgressStatus, hasTaxonomies, signedIn } = this.props;
    const { isCollapsed } = this.state;
    const collapserDirection = isCollapsed ? "down" : "up";
    return (
      <React.Fragment>
        <div className="categoryHeading">
          <h4>{category}</h4>
          <div className="status-collapser">
            {hasTaxonomies && signedIn && (
              <Progress
                status={status}
                responseId={responseId}
                changeProgressStatus={changeProgressStatus}
                showSelectedValueOnly={isCollapsed}
              />
            )}
            <div onClick={this.toggleCollapsed} className={`collapser ${collapserDirection}`}></div>
          </div>
        </div>
        {!isCollapsed && children}
        <hr className="category-separator" />
      </React.Fragment>
    );
  },
});

export default ResourceSection;
