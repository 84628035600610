import React from "react";
import { TopicPageData } from "./types";
import TopicPageBlock from "./TopicPageBlock";
import PageHeader from "./PageHeader";
import Footer from "../Footer";

interface Props {
  topicPage: TopicPageData;
}

function TopicPage({ topicPage }: Props) {
  const { blocks, serviceLocale } = topicPage;

  return (
    <>
      <main className="topic-page">
        <PageHeader {...topicPage} />
        <div className="blocks">
          {blocks.map((block) => (
            <TopicPageBlock
              key={`${block.type}-${block.id}`}
              className="block"
              serviceLocale={serviceLocale}
              {...block}
            />
          ))}
        </div>
      </main>
      <Footer {...{ serviceLocale }} />
    </>
  );
}
export default TopicPage;
