import PropTypes from "prop-types";
import createReactClass from "create-react-class";

const Progress = createReactClass({
  propTypes: {
    status: PropTypes.string,
    responseId: PropTypes.number.isRequired,
    changeProgressStatus: PropTypes.func.isRequired,
    showSelectedValueOnly: PropTypes.bool,
  },

  options: [
    { value: "To Do", text: "To Do", className: "status-not-done" },
    {
      value: "Resources Identified",
      text: "Resources Identified",
      className: "status-not-done",
    },
    {
      value: "Resources In Use",
      text: "Resources In Use",
      className: "status-done",
    },
  ],

  handleOnChange: function (event) {
    const params = {
      responseId: this.props.responseId,
      newStatus: event.target.value,
    };
    this.props.changeProgressStatus(params);
  },

  getStatusClassName() {
    const defaultOption = this.options[0];
    const option = _.find(this.options, (o) => o.value === this.props.status);
    return option ? option.className : defaultOption.className;
  },

  render: function () {
    const defaultOption = this.options[0];
    const { status, showSelectedValueOnly } = this.props;
    if (showSelectedValueOnly) {
      return <div className={this.getStatusClassName()}>{status || defaultOption.text}</div>;
    }
    return (
      <div className="status-select react-select-container">
        <div className="select-label">Status:</div>
        <span className="select-wrap">
          <select
            className="select lists-dropdown"
            value={status || defaultOption.value}
            onChange={this.handleOnChange}
          >
            {this.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </span>
      </div>
    );
  },
});

export default Progress;
