import { ResourceListsProvider } from "./../contexts/ResourceListsContext";
import { AppDataProvider } from "./../contexts/AppDataContext";
import ResourceListsLayout from "./ResourceListsLayout";

export default function ResourceListsApp({ currentUser, initialLists }) {
  return (
    <AppDataProvider currentUser={currentUser}>
      <ResourceListsProvider initialLists={initialLists}>
        <ResourceListsLayout />
      </ResourceListsProvider>
    </AppDataProvider>
  );
}
