import React from "react";
import PropTypes from "prop-types";
import ReactUtils from "./../../react-utils";
import AccountNavBar from "../AccountNavBar";
import AgencyNav from "./AgencyNav";
import { AgencyParams } from "./agency-params";

export default function UpdateDetail({
  update: {
    type,
    name,
    address1,
    requestedChanges,
    relationshipChanges,
    requestStatus,
    responseMessage,
    createdAt,
    resourceUrl,
  },
}) {
  const getChangeRows = (field, changes, i) => {
    let updateParams = null;
    if (type !== "service site relationship") {
      const methodName = `get${type.charAt(0).toUpperCase() + type.slice(1)}Params`;
      updateParams = AgencyParams[methodName]({ crisperEnrolled: true });
    }

    let label = null;

    if (field.includes("mailing")) {
      label = updateParams.mailingAddress.subfields[field].title;
    } else if (
      field.includes("address") ||
      field.includes("street") ||
      field.includes("city") ||
      field.includes("state") ||
      field.includes("postal_code") ||
      field.includes("county")
    ) {
      label = updateParams.address.subfields[field].title;
    } else {
      label = updateParams[field].title;
    }

    const formatVal = (val) => {
      if (val === true) return "Yes";
      if (val === false) return "No";
      if (val === 0) return "0";
      return val;
    };

    return (
      <div
        key={i}
        className={
          i === 0 ? "agency-detail-row change-content" : "agency-detail-row"
        }
      >
        <div className="agency-detail-item-medium">
          <div className="agency-label">{label}</div>
          {formatVal(changes["old"]) ? (
            <div
              className="agency-text-old"
              dangerouslySetInnerHTML={{
                __html: formatVal(changes["old"]),
              }}
            />
          ) : (
            <div className="agency-text-old">{ReactUtils.emptyFieldText()}</div>
          )}
        </div>

        <div className="agency-detail-item-medium">
          <div className="agency-label">{label}</div>
          {formatVal(changes["new"]) ? (
            <div
              dangerouslySetInnerHTML={{
                __html: formatVal(changes["new"]),
              }}
            />
          ) : (
            <div>{ReactUtils.emptyFieldText()}</div>
          )}
        </div>

        {requestStatus === "partially accepted" && (
          <div className="agency-detail-item-no-label">
            {changes["denied"] ? (
              <div className="emphasis denied-text">Denied</div>
            ) : (
              <div className="emphasis accepted-text">Accepted</div>
            )}
          </div>
        )}
      </div>
    );
  };

  const hasAddress =
    type === "site" || (relationshipChanges.length && Object.keys(relationshipChanges[0])[0].includes("AtSite"));

  const getRelationshipText = () => {
    switch (Object.keys(relationshipChanges[0])[0]) {
      case "addProgramAtSite":
        return `Add the ${name} service at this site.`;
      case "removeProgramAtSite":
        return `Remove the ${name} service at this site.`;
      case "addSiteForProgram":
        return `Add the ${address1} site for this service.`;
      case "removeSiteForProgram":
        return `Remove the ${address1} site for this service.`;
    }
  };

  return (
    <div className="agencies-container row">
      <div className="agencies-content">
        <AccountNavBar selection="agency" />
        <div className="agencies-row">
          <AgencyNav selection="changeHistory" />
          <div className="agencies-data-container">
            <div>
              <ol className="breadcrumb top breadcrumb-new">
                <li>
                  <a href="/my211/updates">All Changes</a>
                </li>
                <li className="active">Change</li>
              </ol>
            </div>

            <div className="agency-note">
              Please note that changes to your agency information won’t appear immediately &mdash; a Resource Specialist
              will review and approve or reach out to you with any questions. Once approved, a change may take 24 hours
              to be propagated.
            </div>

            <div className="agency-container">
              <div className="agency-detail-row-first">
                <div className="agency-detail-item">
                  <div className="agency-detail-item">
                    <div className="agency-label">Resource</div>
                    <div>{`${type.charAt(0).toUpperCase()}${type.slice(1)}`}</div>
                  </div>
                </div>

                <div className="agency-detail-item-medium">
                  <div className="agency-label">Date Submitted</div>
                  <div>{ReactUtils.formatTimestamp(createdAt)}</div>
                </div>

                <div className="agency-detail-item-large">
                  <div className="agency-label">{hasAddress ? "Address" : "Name"}</div>
                  <a href={resourceUrl}>{hasAddress ? address1 : name}</a>
                </div>

                <div className="agency-detail-item">
                  <div className="agency-label">Status</div>
                  <div className={`emphasis ${requestStatus}-text`}>{`${requestStatus
                    .charAt(0)
                    .toUpperCase()}${requestStatus.slice(1)}`}</div>
                </div>
              </div>

              <hr />

              <div className="agency-detail-row">
                {type !== "service site relationship" && (
                  <div className="agency-detail-item-medium">
                    <h4 className="agency-detail-subheader">Current</h4>
                  </div>
                )}

                <div className="agency-detail-item-medium">
                  <h4 className="agency-detail-subheader">
                    {`Suggested Change${type === "service site relationship" ? "" : "s"}`}
                  </h4>
                </div>

                {requestStatus === "partially accepted" && (
                  <div className="agency-detail-item">
                    <h4 className="agency-detail-subheader">Accepted?</h4>
                  </div>
                )}
              </div>

              {type === "service site relationship" && relationshipChanges.length ? (
                <div className="agency-detail-row-subcontent">
                  <div className="agency-detail-item">
                    <div>{getRelationshipText()}</div>
                  </div>
                </div>
              ) : (
                Object.keys(requestedChanges).map((fieldName, i) =>
                  getChangeRows(fieldName, requestedChanges[fieldName], i)
                )
              )}

              {responseMessage && (
                <React.Fragment>
                  <hr />

                  <div className="agency-detail-item">
                    <div className="agency-label">Note</div>
                    <div dangerouslySetInnerHTML={{ __html: responseMessage }} />
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

UpdateDetail.propTypes = {
  update: PropTypes.shape({
    type: PropTypes.oneOf(["agency", "site", "service", "service site relationship"]).isRequired,
    name: PropTypes.string,
    address1: PropTypes.string,
    requestedChanges: PropTypes.object,
    relationshipChanges: PropTypes.array,
    requestStatus: PropTypes.string.isRequired,
    responseMessage: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    resourceUrl: PropTypes.string.isRequired,
  }),
};
