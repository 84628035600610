import React from "react";
import PropTypes from "prop-types";
import { apiService } from "./../services";

const ResourceListsContext = React.createContext({});
ResourceListsContext.displayName = "ResourceListsContext";

function ResourceListsProvider({ initialLists, children }) {
  const url = "/resource_lists";

  const [resourceLists, setResourceLists] = React.useState([]);
  const [listsLoading, setListsLoading] = React.useState(true);

  React.useEffect(() => {
    function getLists() {
      return apiService.get(url).then((lists) => {
        setResourceLists(lists);
        setListsLoading(false);
      });
    }

    if (initialLists) {
      setResourceLists(initialLists);
      setListsLoading(false);
    } else {
      getLists();
    }
  }, []);

  function createList(name) {
    return apiService.post(url, { name }).then((list) => setResourceLists([...resourceLists, list]));
  }

  function updateListName(hashid, name) {
    const originalList = resourceLists.find((rl) => rl.hashid === hashid);
    const newList = { ...originalList, name };

    return apiService
      .put(`${url}/${hashid}`, newList)
      .then(() => setResourceLists(resourceLists.map((rl) => (rl.hashid === hashid ? newList : rl))));
  }

  function deleteList(hashid) {
    return apiService
      .delete(`${url}/${hashid}`)
      .then(() => setResourceLists(resourceLists.filter((rl) => rl.hashid !== hashid)));
  }

  function shareList(listInfo) {
    return apiService.post("/share/resource_list", listInfo);
  }

  return (
    <ResourceListsContext.Provider
      value={{ resourceLists, listsLoading, createList, updateListName, deleteList, shareList }}
    >
      {children}
    </ResourceListsContext.Provider>
  );
}

ResourceListsProvider.propTypes = {
  initialLists: PropTypes.array,
  children: PropTypes.node.isRequired,
};

export { ResourceListsContext, ResourceListsProvider };
