import React from "react";
import type { DateDetails, UpcomingEvent, VenueDetails } from "./types";
import PinDrop from "../../icons/PinDrop";
import Calendar from "../../icons/Calendar";

function CalendarEventCard({ title, url, start_date_details, end_date_details, venue, description }: UpcomingEvent) {
  const formatDate = (d: DateDetails) => {
    const date = new Date(d.year, d.month - 1, d.day);
    const month = date.toLocaleString("default", { month: "long" });
    const hour = ((d.hour + 11) % 12) + 1;
    return `${month} ${d.day} @ ${hour}:${String(d.minutes).padStart(2, "0")} ${d.hour >= 12 ? "pm" : "am"}`;
  };

  const htmlParser = new DOMParser();
  const descriptionText = Array.from(htmlParser.parseFromString(description, "text/html").getElementsByTagName("p"))
    .filter((el) => el.innerHTML !== "&nbsp;")
    .reduce((text, p) => `${text} ${p.innerText}`, "");

  const isVenue = (v: typeof venue): v is VenueDetails => !!Object.keys(v).length;

  const addressString = isVenue(venue)
    ? `${htmlParser.parseFromString(venue.venue, "text/html").body.innerText}, ${venue.address} ${venue.city}`
    : "";

  return (
    <div className="calendar-event">
      <>
        <h3 className="event-title" dangerouslySetInnerHTML={{ __html: title }} />
        <div className="event-dates">
          <Calendar />
          {`${formatDate(start_date_details)} - ${formatDate(end_date_details)}`}
        </div>
        {isVenue(venue) && (
          <>
            <a
              href={`https://www.google.com/maps/search/${encodeURIComponent(addressString)}`}
              target="_blank"
              rel="noreferrer"
            >
              <PinDrop />
              {addressString}
            </a>
          </>
        )}
        <p>{descriptionText}</p>
        <a className="event-link" href={url} target="_blank">
          More details &rarr;
        </a>
      </>
    </div>
  );
}

export default CalendarEventCard;
