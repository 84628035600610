import React from "react";
import PropTypes from "prop-types";
import ReferralThankYou from "./ThankYou";

const { useState, useRef } = React;

export default function ReferralResponse(props) {
  const [disableButton, setDisableButton] = useState(true);
  const [sentResponse, setSentResponse] = useState(false);
  const messageRef = useRef("");

  const {
    uuid,
    program_name,
    agency_name,
    for_name,
    for_email,
    for_phone,
    from_name,
    from_email,
    from_phone,
    message,
  } = props.referral;

  const changeMessageLength = (event) => {
    if (event.target.value.length < 1) setDisableButton(true);
    if (event.target.value.length >= 1 && disableButton === true) setDisableButton(false);
  };

  const submitResponse = (newStatus) => {
    setDisableButton(true);
    $.ajax(`/referral/${uuid}`, {
      method: "patch",
      data: JSON.stringify({
        referral: {
          status: newStatus,
          response_message: messageRef.current.value,
        },
      }),
      dataType: "json",
      contentType: "application/json",
    })
      .then(() => {
        $.ajax(`/referral_response_email/${uuid}`, {
          method: "post",
        });
      })
      .done((data) => {
        setSentResponse(true);
      })
      .fail((error) => {
        // TODO: add screen for a failure
        console.log("Failed to send response");
        setSentResponse(true);
      });
  };

  const acceptReferral = (e) => {
    e.preventDefault();
    submitResponse("accepted");
  };

  const declineReferral = (e) => {
    e.preventDefault();
    submitResponse("declined");
  };

  if (sentResponse) {
    return <ReferralThankYou names={{ for_name, from_name }} />;
  }

  return (
    <div className="referrals-container row">
      <div className="referrals-content">
        <div className="table-container referral-response-container">
          <div className="referrals-title">Respond to Referral</div>

          <div className="referral-detail">
            {for_name} has requested {program_name} at {agency_name}
          </div>

          <div className="referral-detail">
            <div className="label">Message</div>
            <div>{message}</div>
          </div>
          <div className="referrals-row">
            <div className="referral-detail col-md-6">
              <div className="label">Details</div>
              <div>{for_name}</div>
              <div>{for_phone}</div>
              <a href={`mailto:${for_email}`}>{for_email}</a>
            </div>
            {(from_name || from_phone || from_email) && (
              <div className="referral-detail">
                <div className="label">Referred by</div>
                <div>{from_name}</div>
                <div>{from_phone}</div>
                <a href={`mailto:${from_email}`}>{from_email}</a>
              </div>
            )}
          </div>
          <div className="referral-detail">
            <div className="textarea-label">Add a Note (Required)</div>
            <form onSubmit={acceptReferral}>
              <textarea
                ref={messageRef}
                onPaste={changeMessageLength}
                onChange={changeMessageLength}
                placeholder="Add next steps, or the reason you’re declining the referral..."
                name="Referral response textarea"
                className="input message referral-input"
              />
              <button
                type="submit"
                disabled={disableButton}
                className={`btn more btn-tall ${!disableButton && "btn-primary"}`}
              >
                Accept
              </button>
              <button
                disabled={disableButton}
                className="btn btn-default more btn-tall btn-cancel"
                onClick={declineReferral}
              >
                Decline
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

ReferralResponse.propTypes = {
  referral: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    program_name: PropTypes.string.isRequired,
    for_name: PropTypes.string.isRequired,
    for_email: PropTypes.string,
    for_phone: PropTypes.string,
    from_name: PropTypes.string,
    from_email: PropTypes.string,
    from_phone: PropTypes.string,
    message: PropTypes.string,
  }),
};
