import React from "react";
import CalendarEventCard from "./CalendarEventCard";
import type { CalendarBlockData } from "./types";
import CalendarIcon from "../../icons/Calendar";
import classNames from "classnames";

function CalendarBlock({ events, description, calendarLink, className }: CalendarBlockData) {
  return (
    <section className={classNames("calendar-block", "shaded-block", className)}>
      <div className="calendar-header">
        <div>
          <h2>Upcoming events</h2>
          <p>{description}</p>
        </div>
        <div className="helpful-links"></div>
      </div>
      <div className="upcoming-events">
        {!events.length ? (
          <p>There are no scheduled events at this time.</p>
        ) : (
          <ul>
            {events.slice(0, 3).map((event) => (
              <li key={event.id}>
                <CalendarEventCard {...event} />
              </li>
            ))}
          </ul>
        )}
        <a className="calendar-link" href={calendarLink} target="_blank" rel="noreferrer">
          <CalendarIcon /> View Calendar
        </a>
        <a className="calendar-link" href="http://uwc.211ct.org/get-help/calendar-form/" target="_blank" rel="noreferrer">
          <CalendarIcon /> Calendar Submission
        </a>
      </div>
    </section>
  );
}

export default CalendarBlock;
