import React from "react";

export default function Computer() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_188_22451"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_188_22451)">
        <path
          d="M2.8665 11.8167C2.53317 11.8167 2.24984 11.7 2.0165 11.4667C1.78317 11.2333 1.6665 10.95 1.6665 10.6167V3.7C1.6665 3.35556 1.78317 3.06667 2.0165 2.83333C2.24984 2.6 2.53317 2.48333 2.8665 2.48333H13.1332C13.4665 2.48333 13.7498 2.6 13.9832 2.83333C14.2165 3.06667 14.3332 3.35556 14.3332 3.7V10.6167C14.3332 10.95 14.2165 11.2333 13.9832 11.4667C13.7498 11.7 13.4665 11.8167 13.1332 11.8167H2.8665ZM2.8665 10.8167H13.1332C13.1776 10.8167 13.2221 10.7973 13.2665 10.7587C13.3109 10.7196 13.3332 10.6722 13.3332 10.6167V3.7C13.3332 3.64444 13.3109 3.59444 13.2665 3.55C13.2221 3.50556 13.1776 3.48333 13.1332 3.48333H2.8665C2.82206 3.48333 2.77762 3.50556 2.73317 3.55C2.68873 3.59444 2.6665 3.64444 2.6665 3.7V10.6167C2.6665 10.6722 2.68873 10.7196 2.73317 10.7587C2.77762 10.7973 2.82206 10.8167 2.8665 10.8167ZM1.4165 13.4833C1.28317 13.4833 1.1665 13.4362 1.0665 13.342C0.966504 13.2473 0.916504 13.1278 0.916504 12.9833C0.916504 12.8389 0.966504 12.7196 1.0665 12.6253C1.1665 12.5307 1.28317 12.4833 1.4165 12.4833H14.5832C14.7165 12.4833 14.8332 12.5307 14.9332 12.6253C15.0332 12.7196 15.0832 12.8389 15.0832 12.9833C15.0832 13.1278 15.0332 13.2473 14.9332 13.342C14.8332 13.4362 14.7165 13.4833 14.5832 13.4833H1.4165Z"
          fill="#125790"
        />
      </g>
    </svg>
  );
}
