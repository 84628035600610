import React from "react";

export default function Phone() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_108_12906"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_108_12906)">
        <path
          d="M12.9501 13.6333C11.6612 13.6333 10.3808 13.3249 9.10876 12.708C7.83632 12.0916 6.7001 11.2833 5.7001 10.2833C4.7001 9.28333 3.89188 8.15 3.27543 6.88333C2.65854 5.61667 2.3501 4.33333 2.3501 3.03333C2.3501 2.83333 2.41676 2.66667 2.5501 2.53333C2.68343 2.4 2.8501 2.33333 3.0501 2.33333H5.21676C5.39454 2.33333 5.54721 2.386 5.67476 2.49133C5.80276 2.59711 5.88343 2.73333 5.91676 2.9L6.3001 4.86667C6.32232 5.03333 6.31676 5.18333 6.28343 5.31667C6.2501 5.45 6.17788 5.56667 6.06676 5.66667L4.53343 7.16667C5.06676 8.05555 5.69721 8.86111 6.42476 9.58333C7.15276 10.3056 7.97788 10.9333 8.9001 11.4667L10.3834 9.96666C10.4945 9.85555 10.6252 9.77778 10.7754 9.73333C10.9252 9.68889 11.0779 9.67778 11.2334 9.7L13.0834 10.0833C13.2501 10.1167 13.3863 10.1944 13.4921 10.3167C13.5974 10.4389 13.6501 10.5889 13.6501 10.7667V12.9333C13.6501 13.1333 13.5834 13.3 13.4501 13.4333C13.3168 13.5667 13.1501 13.6333 12.9501 13.6333ZM4.06676 6.21667L5.2501 5.08333C5.27232 5.06111 5.28632 5.036 5.2921 5.008C5.29743 4.98044 5.29454 4.95555 5.28343 4.93333L5.0001 3.43333C4.98899 3.4 4.97232 3.37489 4.9501 3.358C4.92788 3.34155 4.9001 3.33333 4.86676 3.33333H3.4501C3.41676 3.33333 3.39165 3.34155 3.37476 3.358C3.35832 3.37489 3.3501 3.39444 3.3501 3.41667C3.38343 3.87222 3.45854 4.336 3.57543 4.808C3.69188 5.28044 3.85565 5.75 4.06676 6.21667ZM12.5668 12.6C12.589 12.6 12.6085 12.5916 12.6254 12.5747C12.6419 12.5582 12.6501 12.5389 12.6501 12.5167V11.1167C12.6501 11.0833 12.6419 11.0556 12.6254 11.0333C12.6085 11.0111 12.5834 10.9944 12.5501 10.9833L11.1501 10.7C11.1279 10.6889 11.1057 10.6889 11.0834 10.7C11.0612 10.7111 11.039 10.7222 11.0168 10.7333L9.83343 11.9333C10.2779 12.1444 10.739 12.3056 11.2168 12.4167C11.6945 12.5278 12.1445 12.5889 12.5668 12.6Z"
          fill="#125790"
        />
      </g>
    </svg>
  );
}
