import React from "react";
import ArticleBlock from "./ArticleBlock";
import CalendarBlock from "./CalendarBlock";
import ContentBlock from "./ContentBlock";
import SubTopicBlock from "./SubTopicBlock";
import type { TopicPageBlockData } from "./types";

function TopicPageBlock(block: TopicPageBlockData) {
  switch (block.type) {
    case "ArticleBlock":
      return <ArticleBlock {...block} />;
    case "ContentBlock":
      return <ContentBlock {...block} />;
    case "CalendarBlock":
      return <CalendarBlock {...block} />;
    case "SubTopicBlock":
      return <SubTopicBlock {...block} />;
  }
}

export default TopicPageBlock;
