// Register React and related utilities for use in the asset pipeline
window.React = require("react");
window.ReactDOM = require("react-dom");
window.PropTypes = require("prop-types");

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.SendReferral = require("components/SendReferral").default;
window.HomeCalendar = require("components/HomeCalendar").default;
window.SiteFooter = require("components/Footer").default;
