import React from "react";
import classnames from 'classnames';

interface Props {
  children: React.ReactNode;
  className?: string;
  toggled: boolean;
  onClick: () => void;
}

function ToggleButton({children, className, toggled, ...buttonProps}: Props) {
  return (
    <button {...buttonProps} className={classnames(className, { toggled })}>
      {children}
    </button>
  );
}
export default ToggleButton;
