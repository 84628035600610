import React from "react";
import { ResourceListsContext } from "./../contexts/ResourceListsContext";

export default function CreateResourceList() {
  const { createList } = React.useContext(ResourceListsContext);

  const [listName, setListName] = React.useState("");
  const [error, setError] = React.useState("");
  const [formHidden, hideForm] = React.useState(true);

  function handleListNameChange(event) {
    setListName(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    createList(listName)
      .then(() => {
        hideForm(true);
        setListName("");
        setError("");
      })
      .catch((error) => setError(error.error_message));
  }

  if (formHidden) {
    return (
      <a href="#" onClick={() => hideForm(false)}>
        Create a new resource list...
      </a>
    );
  }

  return (
    <form className="create-container">
      <div className="card-highlight">
        {error && <div>{error}</div>}

        <input
          id="list_name"
          className="input"
          placeholder="New Resource List Name"
          value={listName}
          onChange={handleListNameChange}
        />
        <p className="note">Note: The list name is displayed when shared. Avoid sensitive information.</p>

        <button className="btn btn-block btn-primary submit-list" onClick={handleSubmit}>
          Create List
        </button>
        <button className="btn btn-block btn-default cancel-create-list" type="button" onClick={() => hideForm(true)}>
          Cancel
        </button>
      </div>
    </form>
  );
}
