import React from "react";
import PropTypes from "prop-types";
import ResourceListItem from "./ResourceListItem";

export default function ResourceLists({ lists, sortBy = "date" }) {
  function comparator(a, b) {
    if (sortBy === "date") return new Date(b.created_at) - new Date(a.created_at);

    if (a.name === b.name) return 0;

    return a.name < b.name ? -1 : 1;
  }

  const sortedLists = lists.slice().sort(comparator);
  return (
    <div className="resource-lists">
      {sortedLists.map((l) => (
        <ResourceListItem key={l.hashid} list={l} />
      ))}
    </div>
  );
}

ResourceLists.propTypes = {
  lists: PropTypes.array.isRequired,
  sortBy: PropTypes.oneOf(["date", "name"]).isRequired,
};
