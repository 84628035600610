import React from "react";

const ReactUtils = (() => {
  const emailRegex = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/;

  /**
   * @typedef {Object} ServiceLocale
   * @property {string} abbr - State abbreviation
   * @property {string} stateName - Full state name
   */

  return {
    /**
     * @param {string} address
     * @param {ServiceLocale} serviceLocale
     * @param {Function} [callback]
     */
    geocode: function (address, serviceLocale, callback) {
      if (!(address.indexOf(serviceLocale.abbr) >= 0) && !(address.indexOf(serviceLocale.stateName) >= 0)) {
        address += `, ${serviceLocale.stateName}`;
      }

      const opts = {
        address,
        componentRestrictions: {
          country: "US",
        },
        newForwardGeocoder: true,
      };

      const geocoder = new google.maps.Geocoder();
      return geocoder.geocode(opts, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK && _.isEmpty(results)) {
          // retry once on empty results
          return geocoder.geocode(opts, callback);
        } else {
          return typeof callback === "function" ? callback(results, status) : undefined;
        }
      });
    },

    getServiceArea: function (results, callback) {
      const serviceAreaTypes = ["administrative_area_level_1", "administrative_area_level_2", "locality"];
      if (_.contains(serviceAreaTypes, results[0].types[0])) {
        return callback(results[0].address_components[0].long_name);
      } else {
        const city = _.detect(results[0].address_components, (component) =>
          _.some(_.map(serviceAreaTypes, (t) => _.include(component.types, t)))
        );
        return callback(city?.long_name);
      }
    },

    toUrlVars: (paramsObj) =>
      _.chain(paramsObj)
        .map(function (value, key) {
          if (value && value !== "undefined") {
            return `${key}=${encodeURIComponent(value)}`;
          } else {
            return null;
          }
        })
        .compact()
        .value()
        .join("&"),

    normalizeWebsite: function (url) {
      if (_.isEmpty(url) || /^https?/.test(url)) {
        return url;
      }
      return `http://${url}`;
    },

    /**
     * @param {Address} primaryAddress
     */
    formatAddressString: function (address) {
      return ["street1", "street2", "city", "state"]
        .map((prop) => address[prop])
        .filter((datum) => !!datum)
        .join(", ");
    },

    formatAgencyDataAddress: function (address2, city, state, postalCode) {
      return (
        <React.Fragment>
          {address2 && <div>{address2}</div>}
          <div>
            {city || <em>No City Provided</em>}, {state || <em>No State Provided</em>}{" "}
            {postalCode || <em>No ZIP Provided</em>}
          </div>
        </React.Fragment>
      );
    },

    emptyFieldText: () => <em>None Provided</em>,

    sortBy:
      /**
       *
       * @param {string} sorter The field we want to sort objects by
       *         ex: "created_at" (asc sort), "created_at_desc" (desc sort)
       * @param {Object[]} list The list of objects to sort
       */
      function sortBy(sorter, list) {
        list.sort((a, b) => {
          let [first, last] = [a, b].map((elt) => elt[sorter.replace("-desc", "")].toLowerCase());

          if (sorter.includes("createdAt") || sorter.includes("created_at"))
            [first, last] = [first, last].map((d) => new Date(d.toUpperCase()));

          if (sorter.includes("desc")) [first, last] = [last, first];

          return first > last ? 1 : -1;
        });
        return list;
      },

    filterBy:
      /**
       *
       * @param {Object[]} list The list of objects to filter
       * @param {Object[]} filters The fields and their values to filter by
       *        ex: [{ status: "accepted", type: "all" }] (return the objects of status "accepted" and of any type)
       */
      function filterBy(list, filters) {
        let filteredList = list;

        Object.keys(filters).forEach((field) => {
          // Assume that the fields that shouldn't be filtered are set to "all"
          if (filters[field] !== "all") {
            filteredList = filteredList.filter((elt) => elt[field] === filters[field]);
          }
        });

        return filteredList;
      },

    validateDate: (dateString) => {
      if (dateString === "") return true;

      return dateString.match(/^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/) !== null;
    },

    /**
     * Number between 0 and 120
     */
    validateNumber: (numberString) => {
      if (numberString === "") return true;
      return numberString.match(/^(120|1[01][0-9]|[1-9]?[0-9])$/) !== null;
    },

    validatePhoneNumber: (numberString) => {
      const digits = numberString.replace(/\D*/g, "");

      // Check for 10 digits (which is US phone number without country code)
      // The server only allows/checks for 10 digits, so follow that process here.
      return 10 === digits.length;
    },

    validateEmail: (emailString) => {
      return emailRegex.test(emailString);
    },

    formatTimestamp: (dateString) => {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "long", day: "2-digit" };
      return date.toLocaleDateString(undefined, options);
    },
  };
})();

export default ReactUtils;
