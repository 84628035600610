import { useState } from "react";
import PropTypes from "prop-types";

export default function AgencyConfirmationModal({ type, item, setRelationToConfirm, submitRelation }) {
  const [enabled, setEnabled] = useState(true);

  const title = type === "service" ? item.name : item.address_1;
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

  return (
    <div className="modal-open">
      <div
        className="modal fade send-referral in"
        role="dialog"
        tabIndex="-1"
        style={{ zIndex: 9999, display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content agency-modal-content">
            <div className="modal-header">
              <div className="label">Confirm Removal</div>
              <h3 className="">
                Are you sure you want to remove the {title} {type}?
              </h3>
            </div>
            <div className="agency-modal-subtitle">
              If you need to add it back later, you’ll need to use the form linked in the ‘{typeCapitalized}s Offered’
              panel.
            </div>
            <button
              className="btn more btn-tall btn-danger"
              onClick={() => {
                setEnabled(false);
                submitRelation(item.number, "remove");
              }}
              disabled={!enabled}
            >
              Remove {typeCapitalized}
            </button>
            <button
              className="btn btn-default more btn-tall btn-cancel"
              onClick={() => {
                setEnabled(false);
                setRelationToConfirm(null);
              }}
              disabled={!enabled}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

AgencyConfirmationModal.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  setRelationToConfirm: PropTypes.func.isRequired,
  submitRelation: PropTypes.func.isRequired,
};
