import PropTypes from "prop-types";
import QuestionAnswer from "./QuestionAnswer";
import createReactClass from "create-react-class";

const ScreenerConfirmation = createReactClass({
  propTypes: {
    responseSet: PropTypes.string,
  },

  getInitialState: function () {
    const responseSet = JSON.parse(this.props.responseSet);
    const groupedAnswers = _.values(_.groupBy(responseSet.responses, (response) => response.question_id));
    const responses = groupedAnswers.map((qa) => {
      return {
        question: qa[0].question.text,
        order: qa[0].question.order,
        type: qa[0].question.type,
        answers: qa.map((a) => a.answer.text),
        responseId: qa[0].id,
      };
    });
    return {
      responses: _.sortBy(responses, "order"),
      token: responseSet.token,
      surveyId: responseSet.survey_id,
    };
  },

  render: function () {
    const { responses, token, surveyId } = this.state;
    return (
      <div className="row survey-container">
        <div className="summary confirmation">
          <div className="survey-content">
            <div className="survey-form">
              <div className="confirmation-header">
                <a href={`/assessments/${surveyId}`}>Back to Screener</a>
                <a className="survey-confirm btn btn-primary" href={`/assessments/response_sets/${token}`}>
                  Continue to Screener Worksheet
                </a>
              </div>
              <h2>Confirm your responses</h2>
              <div className="answers">
                {responses.map((questionAnswer, index) => (
                  <QuestionAnswer key={index} questionAnswer={questionAnswer} />
                ))}
              </div>
              <div className="confirmation-footer">
                <a className="btn btn-primary" href={`/assessments/response_sets/${token}`}>
                  Continue to Screener Worksheet
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default ScreenerConfirmation;
