import React from "react";
import PropTypes from "prop-types";
import ReactUtils from "./../../react-utils";
import Input from "./Input";
import { AgencyUtils } from "./agency-utils";

const { useState } = React;

export default function ChangeField({
  fieldName,
  initialFieldValues,
  setFieldsState,
  fieldsState,
  submitting,
  setInvalidInputs,
}) {
  const subfields = initialFieldValues[fieldName].subfields;
  const [errorFields, setErrorFields] = useState(new Set());

  const formatAddressFields = () => {
    // Assume this order for the fields in the Address multifield
    const [addr1, addr2, city, state, zip, county] = Object.keys(subfields);
    return (
      <React.Fragment>
        <div>{subfields[addr1].initialValue || <em>No Street Address Provided</em>}</div>
        <div>{subfields[addr2].initialValue || ""}</div>
        <div>
          {subfields[city].initialValue || <em>No City Provided</em>},{" "}
          {subfields[state].initialValue || <em>No State Provided</em>}{" "}
          {subfields[zip].initialValue || <em>No ZIP Provided</em>}
        </div>
        {subfields[county] && (
          // Assume county is the only optional field in the Address multifield
          <div>{subfields[county].initialValue || <em>No County Provided</em>}</div>
        )}
      </React.Fragment>
    );
  };

  const toggleErrorFields = (inputId, valid) => {
    const currentErrorFields = new Set(errorFields);
    if (valid) currentErrorFields.delete(inputId);
    else currentErrorFields.add(inputId);

    setErrorFields(currentErrorFields);

    if (currentErrorFields.size > 0) setInvalidInputs(true);
    else setInvalidInputs(false);
  };

  const validateField = (fieldName, value = "") => {
    let formatPlaceholder;
    if (subfields) {
      formatPlaceholder = Object.values(initialFieldValues)[0].subfields[fieldName].placeholder;
    } else {
      formatPlaceholder = initialFieldValues[fieldName].placeholder;
    }

    switch (formatPlaceholder) {
      case AgencyUtils.datePlaceholder:
        return toggleErrorFields(fieldName, ReactUtils.validateDate(value));
      case "Number from 0 to 120":
        return toggleErrorFields(fieldName, ReactUtils.validateNumber(value));
      case AgencyUtils.emailPlaceholder:
        return toggleErrorFields(fieldName, ReactUtils.validateEmail(value));
    }
  };

  return (
    <div className="agency-detail-item">
      {fieldsState[fieldName] !== undefined ||
      (subfields && Object.keys(subfields).find((subfield) => fieldsState[subfield] !== undefined)) ? (
        <div className="change-field-container">
          <div className="change-content">
            <div className="agency-label">{initialFieldValues[fieldName].title}</div>
            <div className="field-description">{initialFieldValues[fieldName].description}</div>
            {Object.entries(subfields || { [fieldName]: initialFieldValues[fieldName] }).map(([name, subfield]) => (
              <Input
                key={name}
                type={subfield.inputType}
                placeholder={subfield.placeholder || subfield.title}
                name={name}
                picklist={subfield.picklist}
                singleOption={subfield.singleOption}
                splitChar={subfield.splitChar}
                setFieldsState={setFieldsState}
                fieldsState={fieldsState}
                errorFields={errorFields}
                validateField={validateField}
              />
            ))}
          </div>

          <button
            className="agency-button-link cancel"
            disabled={submitting}
            onClick={() => {
              let newFieldsState = { ...fieldsState };

              if (subfields) {
                Object.keys(subfields).map((subfield) => {
                  if (fieldsState[subfield] !== undefined) {
                    delete newFieldsState[subfield];
                    validateField(subfield);
                  }
                });
              } else {
                delete newFieldsState[fieldName];
                validateField(fieldName);
              }

              setFieldsState(newFieldsState);
            }}
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="change-field-container">
          <div className="change-content">
            <div className="agency-label">{initialFieldValues[fieldName].title}</div>
            <div className="field-description">{initialFieldValues[fieldName].description}</div>
            {subfields ? (
              // Addresses are the only multifield fields at the moment
              formatAddressFields()
            ) : (
              <div className="field-value">
                {initialFieldValues[fieldName].initialValue || ReactUtils.emptyFieldText()}
              </div>
            )}
          </div>

          <button
            className="agency-button-link change"
            disabled={submitting}
            onClick={() => {
              let newFieldsState = {};
              if (subfields) {
                Object.keys(subfields).map(
                  (subfield) => (newFieldsState[subfield] = subfields[subfield].initialValue || null)
                );
              } else {
                newFieldsState[fieldName] = initialFieldValues[fieldName].initialValue || null;
              }
              setFieldsState({
                ...fieldsState,
                ...newFieldsState,
              });
            }}
          >
            <div className="edit-ico"></div>
            Change
          </button>
        </div>
      )}
    </div>
  );
}

ChangeField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  initialFieldValues: PropTypes.object.isRequired,
  setFieldsState: PropTypes.func.isRequired,
  fieldsState: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  setInvalidInputs: PropTypes.func.isRequired,
};
