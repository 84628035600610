import React from "react";

import PropTypes from "prop-types";
import MobileNav from "./MobileNav";
import Chat from "./Chat";
import createReactClass from "create-react-class";

const Header = createReactClass({
  propTypes: {
    homePath: PropTypes.string,
    accountPath: PropTypes.string,
    signOutPath: PropTypes.string,
    authenticationPath: PropTypes.string,
    registrationPath: PropTypes.string,
    user: PropTypes.string,
    isAgencyUser: PropTypes.bool,
    chatAvailable: PropTypes.bool,
    resourceListsPath: PropTypes.string,
    myReferralsPath: PropTypes.string,
    myAgencyDataPath: PropTypes.string,
    organization: PropTypes.object,
  },

  getInitialState: function () {
    return {
      mobileNavVisible: false,
      navBreakpoint: this.props.organization.navbarBreakpoint || "screen-xlarge",
    };
  },

  toggleMobileNav: function () {
    this.setState({ mobileNavVisible: !this.state.mobileNavVisible });
  },

  render: function () {
    const {
      homePath,
      accountPath,
      signOutPath,
      authenticationPath,
      registrationPath,
      user,
      isAgencyUser,
      chatAvailable,
      resourceListsPath,
      myReferralsPath,
      myAgencyDataPath,
      organization,
    } = this.props;

    const { mobileNavVisible, navBreakpoint } = this.state;

    return (
      <header className={mobileNavVisible ? "site-header show-nav" : "site-header"}>
        <nav>
          <a className="home-link" href="/">
            <h1 className="logo-211">211</h1>
          </a>
          <a className="nav-link search-link" href="/">
            <i style={{ marginRight: "6px" }} className="glyphicon glyphicon-search"></i>
            Search
            <span className="for-resources">&nbsp;&nbsp;for resources</span>
          </a>
          <button className={`pull-right toggle-nav ${navBreakpoint}`} onClick={this.toggleMobileNav}>
            <span className="burger-x"></span>
          </button>
          <span className="google-translate pull-right">
            <div id="google_translate_element"></div>
          </span>
          <ul className={`navigation-menu pull-right ${navBreakpoint}`}>
            {Object.keys(this.props.organization.navbar).map((title, i) => {
              if (typeof this.props.organization.navbar[title] === "object") {
                return (
                  <li className="nav-item dropdown" style={{ display: "inline-block" }} key={i}>
                    {this.props.organization.navbar[title].href ? (
                      <React.Fragment>
                        <a className="nav-link" id={title} href={this.props.organization.navbar[title].href}>
                          {title}
                        </a>
                        <a
                          className="dropdown-toggle account-label nav-link desktop-only"
                          aria-labelledby={title}
                          aria-expanded
                          aria-haspopup
                          data-toggle="dropdown"
                          type="button"
                          href="#"
                        />
                      </React.Fragment>
                    ) : (
                      <a
                        className="dropdown-toggle account-label nav-link desktop-only"
                        id={title}
                        aria-expanded
                        aria-haspopup
                        data-toggle="dropdown"
                        type="button"
                        href="#"
                      >
                        {title}
                      </a>
                    )}
                    <ul className="dropdown-menu" aria-labelledby={title}>
                      {Object.keys(this.props.organization.navbar[title].items).map((subtitle, i) => {
                        return (
                          <li key={i}>
                            <a href={this.props.organization.navbar[title].items[subtitle]}>{subtitle}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li className="nav-item" key={i}>
                    <a className="nav-link desktop-only" href={this.props.organization.navbar[title]}>
                      {title}
                    </a>
                  </li>
                );
              }
            })}
            {this.props.organization.flags && this.props.organization.flags.enableChat && (
              <li className="chat-nav nav-item">
                <Chat chatAvailable={chatAvailable} organization={organization} />
              </li>
            )}
            {user && (
              <li className="login-nav nav-item">
                <div className="dropdown" style={{ display: "inline-block" }}>
                  <a
                    className="account-label desktop-only dropdown-toggle nav-link"
                    id="accountMenu"
                    aria-expanded="true"
                    aria-haspopup="true"
                    data-toggle="dropdown"
                    type="button"
                    href="#"
                  >
                    My 211 Account
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="accountMenu">
                    {isAgencyUser && (
                      <li>
                        <a href={myAgencyDataPath}>Agency</a>
                      </li>
                    )}
                    <li>
                      <a href={resourceListsPath}>Resource Lists</a>
                    </li>
                    {this.props.organization.userFeatures && this.props.organization.userFeatures.screeners && (
                      <li>
                        <a href="/my211/screeners">Screeners</a>
                      </li>
                    )}
                    {this.props.organization.userFeatures && this.props.organization.userFeatures.referrals && (
                      <li>
                        <a href="/my211/referrals">Service Requests</a>
                      </li>
                    )}
                    <li>
                      <a href={accountPath}>Account Settings</a>
                    </li>
                    <li>
                      <a href={signOutPath} data-method="delete">
                        Log out
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            )}
            {!user && (
              <React.Fragment>
                <li className="login-nav nav-item">
                  <a className="desktop-only nav-link" href={authenticationPath}>
                    Login
                  </a>
                </li>
                <li className="nav-item signup-nav">
                  <a className="border-nav-item desktop-only nav-link signup-link" href={registrationPath}>
                    Sign up
                  </a>
                </li>
              </React.Fragment>
            )}
          </ul>
        </nav>
        <nav className="mobile-nav" id="mobile-nav-region">
          <div className="mobile-nav-view">
            <MobileNav
              homePath={homePath}
              accountPath={accountPath}
              signOutPath={signOutPath}
              authenticationPath={authenticationPath}
              registrationPath={registrationPath}
              user={user}
              isAgencyUser={isAgencyUser}
              chatAvailable={chatAvailable}
              resourceListsPath={resourceListsPath}
              mobileNavVisible={mobileNavVisible}
              myReferralsPath={myReferralsPath}
              myAgencyDataPath={myAgencyDataPath}
              organization={organization}
            />
          </div>
        </nav>
      </header>
    );
  },
});

export default Header;
