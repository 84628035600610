import React, { useState } from "react";

import Checkboxes from "./Checkboxes";

export default {
  title: "Checkboxes",
  component: Checkboxes,
};

const Template = (args) => {
  const [selected, setSelected] = useState([]);
  const toggleSelected = (item) =>
    selected.includes(item) ? setSelected(selected.filter((it) => it !== item)) : setSelected([...selected, item]);
  const stateProps = { handleItemClick: toggleSelected, selected };
  const fullArgs = { ...args, ...stateProps };
  return <Checkboxes {...fullArgs} />;
};

export const CheckboxGroup = Template.bind({});
CheckboxGroup.args = {
  items: ["Transfiguration", "Muggle Studies", "Charms"],
};
