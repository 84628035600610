import React from "react";
import PropTypes from "prop-types";

export default function LoadingScreen({ children = "Loading" }) {
  return (
    <div className="loading">
      <div className="loading-overlay">
        <div className="loading-container">
          <div className="loading-content">
            <div className="preloader">
              <div className="dot1" />
              <div className="dot2" />
            </div>

            <p className="direct">{children}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

LoadingScreen.propTypes = {
  children: PropTypes.string,
};
