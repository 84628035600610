import React from "react";

const AppDataContext = React.createContext({ currentUser: {}, appPaths: {} });
AppDataContext.displayName = "AppDataContext";

function AppDataProvider({ currentUser, children }) {
  const appPaths = window.UW211.APP_PATHS;

  return <AppDataContext.Provider value={{ currentUser, appPaths }}>{children}</AppDataContext.Provider>;
}

export { AppDataContext, AppDataProvider };
