import React from "react";

function CTFooter() {
  return (
    <footer className="site-footer">
      <div className="wrap">
        <nav className="navigation-footer">
          <div className="icon logo-unitedway"></div>
          <div className="footer-links">
            <ul className="menu-nav">
              <li className="nav-item">
                <strong>
                  <a className="nav-link top-level" href="http://uwc.211ct.org/about/">
                    About 2-1-1
                  </a>
                </strong>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="http://www.ctunitedway.org/aboutus/job-postings/"
                  rel="noopener"
                  target="_blank"
                >
                  Careers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://uwc.211ct.org/about/privacypolicy/">
                  Privacy Policy
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.ctunitedway.org/mediacenter/media-request/">
                  Media Requests
                </a>
              </li>
            </ul>
            <ul className="menu-nav">
              <li className="nav-item">
                <strong>
                  <a className="nav-link top-level" href="http://uwc.211ct.org/">
                    Get Help
                  </a>
                </strong>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://www.211childcare.org/">
                  2-1-1 Child Care
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://cdi.211ct.org/">
                  2-1-1 Child Development
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.ctcare4kids.com/">
                  Care4Kids
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://www.cthcvp.org/">
                  Register for Housing Voucher Notifications
                </a>
              </li>
            </ul>
            <ul className="menu-nav">
              <li className="nav-item">
                <strong>
                  <a className="nav-link top-level" href="http://uwc.211ct.org/professionals/">
                    Professionals
                  </a>
                </strong>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://ct.211counts.org/" rel="noopener" target="_blank">
                  2-1-1 Counts
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://uwc.211ct.org/professionals/directories/">
                  Directories
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://uwc.211ct.org/professionals/materials">
                  Materials
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="http://uwc.211ct.org/professionals/reports/">
                  Publications
                </a>
              </li>
            </ul>
            <ul className="menu-nav">
              <li className="nav-item">
                <strong>
                  <a className="nav-link top-level" href="http://uwc.211ct.org/contact/">
                    Contact
                  </a>
                </strong>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="tel: 211">
                  Dial 2-1-1
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="tel: 18002031234">
                  Dial 1-800-203-1234
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="tel: 711">
                  TTY/Relay: 7-1-1
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="footer-bottom">
          <p className="call-out">
            2-1-1 is a program of United Way of Connecticut and is supported by the State of Connecticut and Connecticut
            United Ways.
          </p>
          <ul className="menu-social">
            <li className="nav-item">
              <a className="nav-link" href="https://www.facebook.com/211ct" aria-label="facebook link">
                <i className="ico ico-facebook"></i>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://twitter.com/211CT" aria-label="twitter link">
                <i className="ico ico-twitter"></i>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.youtube.com/user/ctunitedway" aria-label="youtube link">
                <i className="ico ico-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default CTFooter;
