import PropTypes from "prop-types";
import createReactClass from "create-react-class";

const Category = createReactClass({
  propTypes: {
    categoryText: PropTypes.string,
    removeCategory: PropTypes.func,
    taxonomyCode: PropTypes.string,
  },

  onClick: function (taxonomyCode) {
    return () => this.props.removeCategory(taxonomyCode);
  },

  render: function () {
    const { categoryText, taxonomyCode } = this.props;
    return (
      <div className="search-selection">
        <span className="category">
          {categoryText}{" "}
          <span onClick={this.onClick(taxonomyCode)} className="pointer">
            &nbsp;X&nbsp;{" "}
          </span>
        </span>
      </div>
    );
  },
});

export default Category;
