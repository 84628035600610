import PropTypes from "prop-types";
import createReactClass from "create-react-class";

const Answer = createReactClass({
  propTypes: {
    label: PropTypes.string,
    answerId: PropTypes.number,
    questionType: PropTypes.string,
  },

  render: function () {
    const { label, answerId, questionType } = this.props;
    if (questionType === "check") {
      return (
        <div className="check-box-answer checked">
          <div className="answer-option">
            <input id={answerId} type="checkbox" checked readOnly />
            <label className="answer-option-check-box" htmlFor={answerId}>
              {label}
            </label>
          </div>
        </div>
      );
    }
    if (questionType === "radio") {
      return (
        <div className="radio-button-answer checked">
          <div className="answer-option">
            <input id={answerId} type="radio" checked readOnly />
            <label className="answer-option-radio-btn" htmlFor={answerId}>
              {label}
            </label>
          </div>
        </div>
      );
    }
    return <p>{label}</p>;
  },
});

export default Answer;
