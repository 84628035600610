import PropTypes from "prop-types";
import AccountNavBar from "../AccountNavBar";
import AgencyNav from "./AgencyNav";
import ListItem from "./ListItem";

export default function AgenciesServices(props) {
  return (
    <div className="agencies-container row">
      <div className="agencies-content">
        <AccountNavBar selection="agency" />
        <div className="agencies-row">
          <AgencyNav selection="services" />
          <div className="agencies-data-container">
            <h1 className="agencies-title">Services</h1>
            {props.services.map((service) => (
              <ListItem key={service.number} type={"service"} {...service} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

AgenciesServices.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
    })
  ),
};
