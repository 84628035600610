import PropTypes from "prop-types";
import ReactUtils from "./../../react-utils";

export default function ReferralItem(props) {
  const { uuid, program_name, agency_name, for_name, created_at, status } = props.referral;

  return (
    <tr>
      <td>
        <a href={`/my211/referrals/${uuid}`} className="small-label">
          {agency_name}
        </a>
        <div className="sub-content">{program_name}</div>
      </td>
      <td>
        <div>{for_name}</div>
        <div className="sub-content">{ReactUtils.formatTimestamp(created_at)}</div>
      </td>
      <td className={`emphasis ${status}-text`}>{status.charAt(0).toUpperCase() + status.slice(1)}</td>
    </tr>
  );
}

ReferralItem.propTypes = {
  referral: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    program_name: PropTypes.string.isRequired,
    agency_name: PropTypes.string.isRequired,
    for_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
};
