import ReactUtils from "./../../react-utils";
import AccountNavBar from "../AccountNavBar";

export default function Referral(props) {
  const {
    program_name,
    agency_name,
    agency_address,
    agency_website,
    agency_email,
    agency_phone,
    for_name,
    for_email,
    for_phone,
    message,
    response_message,
    created_at,
    status,
  } = props.referral;

  const titleToValueMap = {
    "date sent": ReactUtils.formatTimestamp(created_at),
    name: for_name,
    email: for_email,
    phone: for_phone,
    message: message,
  };

  const statusCopies = {
    pending: "Your service request was sent. You will be notified by email when the agency responds.",
    accepted: "The agency will reach out to you soon.",
    declined: "The agency has declined your service request.",
  };

  return (
    <div className="referrals-container row">
      <div className="referrals-content">
        <AccountNavBar selection="referrals" />
        <div className="row">
          <div className="col-md-8 col-xs-12">
            <h1 className="lists-index dark-blue-text">Service Requests</h1>
          </div>
        </div>
        <div>
          <ol className="breadcrumb top breadcrumb-new">
            <li>
              <a href="/my211/referrals">All Service Requests</a>
            </li>
            <li className="active">{agency_name}</li>
          </ol>
        </div>
        <div className="table-container referral-container">
          <div className="label dark-blue-text">{program_name}</div>
          <div className="referral-header dark-blue-text">{agency_name}</div>
          <div className="referrals-row">
            <a
              href={`http://maps.google.com/maps?daddr=${encodeURIComponent(agency_address)}`}
              target="_blank"
              rel="noopener"
              className="resource-location small-label referrals-row"
            >
              <i className="referral-marker-ico ico ico-marker"></i>
              <address>{agency_address}</address>
            </a>
            {agency_website && (
              <a href={agency_website} className="small-label">
                Website
              </a>
            )}
            {agency_email && (
              <a href={`mailto:${agency_email}`} className="small-label">
                Email
              </a>
            )}
            {agency_phone && <div>{agency_phone}</div>}
          </div>
          <div className="referral-detail">
            <div className="label">Status</div>
            <div className="referrals-row">
              <i className={`referral-ico ${status}-icon`}></i>
              <div className={`emphasis status-text ${status}-text`}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </div>
            </div>
            <div>{statusCopies[status]}</div>
          </div>
          {response_message && (
            <div className="referral-detail referral-response">
              <div className="label-no-transform">{agency_name} said:</div>
              <div>{response_message}</div>
            </div>
          )}
          {Object.keys(titleToValueMap).map((title) => {
            if (titleToValueMap[title]) {
              return (
                <div className="referral-detail" key={title}>
                  <div className="label">{title}</div>
                  <div>{titleToValueMap[title]}</div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

Referral.propTypes = {
  referral: PropTypes.shape({
    program_name: PropTypes.string.isRequired,
    agency_name: PropTypes.string.isRequired,
    agency_address: PropTypes.string.isRequired,
    agency_website: PropTypes.string,
    agency_email: PropTypes.string,
    agency_phone: PropTypes.string,
    for_name: PropTypes.string.isRequired,
    for_email: PropTypes.string,
    for_phone: PropTypes.string,
    message: PropTypes.string.isRequired,
    response_message: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
};
