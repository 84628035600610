import PropTypes from "prop-types";
import ReactUtils from "./../react-utils";
import SendReferralModal from "./SendReferralModal";
import createReactClass from "create-react-class";

const ResourceList = createReactClass({
  propTypes: {
    resourceList: PropTypes.object,
    toggleModalState: PropTypes.func,
    isModalDisplayed: PropTypes.bool,
    user: PropTypes.object,
    surveyName: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    responseId: PropTypes.number.isRequired,
  },

  getInitialState: function () {
    return { selectedResource: null };
  },

  // based on logic from models/search_result.coffee
  getPhoneNumber: function (resource) {
    if (resource.program_site_phones.phone_1) {
      return resource.program_site_phones.phone_1;
    }
    if (resource.program_site_phones.phone_2) {
      return resource.program_site_phones.phone_2;
    }
    if (resource.program_phones.phone_1) {
      return resource.program_phones.phone_1;
    }
    if (resource.site_phones.phone_1) {
      return resource.site_phones.phone_1;
    }
    if (resource.agency_phones.phone_1) {
      return resource.agency_phones.phone_1;
    }
    return null;
  },

  handleSendEmailClick: function (resource) {
    this.setState({ selectedResource: resource });
    this.props.toggleModalState();
  },

  render: function () {
    const { isModalDisplayed, toggleModalState, user, surveyName, category, token, responseId } = this.props;
    const { name, id, items, hashid } = this.props.resourceList;
    const { selectedResource } = this.state;
    return (
      <div className="resource-list-container">
        <div className="resource-list-header">
          <label>
            <a href={`/my211/resource-lists/${hashid}`}>{name}</a>
          </label>
        </div>
        <table className="resource-list">
          <tbody>
            {items.map((resource) => {
              const { program_site_id, referral_emails } = resource;
              const phone = this.getPhoneNumber(resource);
              return (
                <tr key={program_site_id}>
                  <td>
                    <div>
                      <a
                        href={`/search/${program_site_id}?survey_name=${surveyName}&category=${category}&token=${token}&response_id=${responseId}`}
                      >
                        {resource.name}
                      </a>
                    </div>
                    <div>
                      {resource.address_1}, {resource.city}, {resource.state}
                    </div>
                  </td>
                  <td>{phone}</td>
                  <td>
                    {resource.site_website && (
                      <a href={ReactUtils.normalizeWebsite(resource.site_website)} target="_blank" rel="noopener">
                        Website
                      </a>
                    )}
                  </td>
                  <td>
                    {referral_emails && !_.isEmpty(referral_emails) && (
                      <a onClick={() => this.handleSendEmailClick(resource)}>Send Email</a>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {isModalDisplayed && selectedResource && (
          <SendReferralModal
            toggleModalState={toggleModalState}
            user={user}
            programName={selectedResource.name}
            programSiteId={selectedResource.program_site_id}
          />
        )}
      </div>
    );
  },
});

export default ResourceList;
