import PropTypes from "prop-types";
import AccountNavBar from "../AccountNavBar";
import AgencyNav from "./AgencyNav";
import ListItem from "./ListItem";

export default function AgenciesSites(props) {
  return (
    <div className="agencies-container row">
      <div className="agencies-content">
        <AccountNavBar selection="agency" />
        <div className="agencies-row">
          <AgencyNav selection="sites" />
          <div className="agencies-data-container">
            <h1 className="agencies-title">Sites</h1>
            {props.sites.map((site) => (
              <ListItem key={site.number} type={"site"} {...site} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

AgenciesSites.propTypes = {
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
    })
  ),
};
