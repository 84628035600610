import { AgencyUtils, StateAgencyUtils } from "./agency-utils";

const state = UW211.ORGANIZATION.serviceLocale && UW211.ORGANIZATION.serviceLocale.abbr.toLowerCase();
const stateAgencyUtils = StateAgencyUtils[state];

export const StateAgencyParams = {
  ar: {
    getAgencyParams: (agency) => ({
      name: new AgencyUtils.Field({
        title: "Account Name",
        initialValue: agency.name,
      }),
      phone: new AgencyUtils.Field({
        title: "Toll Free",
        initialValue: agency.phone1,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      phone2: new AgencyUtils.Field({
        title: "Hotline",
        initialValue: agency.phone2,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      phone3: new AgencyUtils.Field({
        title: "Business Line",
        initialValue: agency.phone3,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      phone4: new AgencyUtils.Field({
        title: "Fax",
        initialValue: agency.phone4,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      phone5: new AgencyUtils.Field({
        title: "TTY",
        initialValue: agency.phone5,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      mainEmailAddress: new AgencyUtils.Field({
        title: "Agency Email",
        initialValue: agency.email,
        placeholder: AgencyUtils.emailPlaceholder,
      }),
      address: new AgencyUtils.MultiField("Physical Address", {
        physical_street_1: new AgencyUtils.Field({
          title: "Physical Address 1",
          initialValue: agency.street1,
        }),
        physical_street_2: new AgencyUtils.Field({
          title: "Physical Address 2",
          initialValue: agency.street2,
        }),
        physical_city: new AgencyUtils.Field({
          title: "Physical Address City",
          initialValue: agency.city,
        }),
        physical_state: new AgencyUtils.Field({
          title: "Physical Address State",
          initialValue: agency.state,
          inputType: "select",
          picklist: AgencyUtils.statesList,
        }),
        physical_postal_code: new AgencyUtils.Field({
          title: "Physical Address Zip",
          initialValue: agency.postalCode,
        }),
        county: new AgencyUtils.Field({
          title: "Physical Address County",
          initialValue: agency.county,
          inputType: "select",
          picklist: StateAgencyUtils["ar"].countiesList,
        }),
      }),
      mailingAddress: new AgencyUtils.MultiField("Mailing Address", {
        mailing_street_1: new AgencyUtils.Field({
          title: "Mailing Address 1",
          initialValue: agency.mailingStreet1,
        }),
        mailing_street_2: new AgencyUtils.Field({
          title: "Mailing Address 2",
          initialValue: agency.mailingStreet2,
        }),
        mailing_city: new AgencyUtils.Field({
          title: "Mailing Address City",
          initialValue: agency.mailingCity,
        }),
        mailing_state: new AgencyUtils.Field({
          title: "Mailing Address State",
          initialValue: agency.mailingState,
          inputType: "select",
          picklist: AgencyUtils.statesList,
        }),
        mailing_postal_code: new AgencyUtils.Field({
          title: "Mailing Address Zip",
          initialValue: agency.mailingPostalCode,
        }),
      }),
      hours: new AgencyUtils.Field({
        title: "Hours of Operation",
        initialValue: agency.hoursOfOperation,
        description: "What are the general office hours for the agency?",
      }),
      senior_worker_name: new AgencyUtils.Field({
        title: "Senior Worker Name",
        initialValue: agency.seniorWorkerName,
      }),
      senior_worker_title: new AgencyUtils.Field({
        title: "Senior Worker Title",
        initialValue: agency.seniorWorkerTitle,
      }),
      senior_worker_email: new AgencyUtils.Field({
        title: "Senior Worker Email",
        initialValue: agency.seniorWorkerEmail,
      }),
      senior_worker_phone: new AgencyUtils.Field({
        title: "Senior Worker Phone",
        initialValue: agency.seniorWorkerPhone,
      }),
      main_contact_name: new AgencyUtils.Field({
        title: "Main Contact Name",
        initialValue: agency.mainContactName,
      }),
      main_contact_title: new AgencyUtils.Field({
        title: "Main Contact Title",
        initialValue: agency.mainContactTitle,
      }),
      main_contact_email: new AgencyUtils.Field({
        title: "Main Contact Email",
        initialValue: agency.mainContactEmail,
      }),
      main_contact_phone: new AgencyUtils.Field({
        title: "Main Contact Phone",
        initialValue: agency.mainContactPhone,
      }),
    }),
    getSiteParams: (site) => ({
      name: new AgencyUtils.Field({
        title: "Name",
        initialValue: site.name,
      }),
      mainEmailAddress: new AgencyUtils.Field({
        title: "Email",
        initialValue: site.email,
      }),
      website: new AgencyUtils.Field({
        title: "Website",
        initialValue: site.website,
      }),
      hours_of_operation: new AgencyUtils.Field({
        title: "Hours of Operation",
        initialValue: site.hoursOfOperation,
        description: "What are the general office hours for this location?",
      }),
      address: new AgencyUtils.MultiField("Physical Address", {
        address_1: new AgencyUtils.Field({
          title: "Physical Address 1",
          initialValue: site.address1,
        }),
        address_2: new AgencyUtils.Field({
          title: "Physical Address 2",
          initialValue: site.address2,
        }),
        city: new AgencyUtils.Field({
          title: "Physical Address City",
          initialValue: site.city,
        }),
        state: new AgencyUtils.Field({
          title: "Physical Address State",
          initialValue: site.state,
          inputType: "select",
          picklist: AgencyUtils.statesList,
        }),
        postal_code: new AgencyUtils.Field({
          title: "Physical Address Zip",
          initialValue: site.postalCode,
        }),
        county: new AgencyUtils.Field({
          title: "Physical Address County",
          initialValue: site.county,
          inputType: "select",
          picklist: StateAgencyUtils["ar"].countiesList,
        }),
      }),
      alternate_name: new AgencyUtils.Field({
        title: "Aliases",
        initialValue: site.aliases,
        description: "What is the name of this location/building?",
      }),
    }),
    getServiceParams: (service) => ({
      name: new AgencyUtils.Field({
        title: "Program Name",
        initialValue: service.name,
      }),
      alternate_name: new AgencyUtils.Field({
        title: "Alias",
        initialValue: service.alternateName,
        description: "What names does this service go by? Does the program have an official name?",
      }),
      description: new AgencyUtils.Field({
        title: "Program Description",
        initialValue: service.description,
        description:
          "How would you describe this service? 2-1-1 lists descriptions as clearly and concisely as possible while trying to keep the description at a sixth grade reading level. We may edit the description provided in order to make it fit our formatting guidelines.",
        inputType: "richtext",
      }),
      website: new AgencyUtils.Field({
        title: "Web Address",
        initialValue: service.website,
        description: "Is there a web page for information related to this service?",
      }),
      program_hours_of_operation: new AgencyUtils.Field({
        title: "Hours of Operation",
        initialValue: service.programHoursOfOperation,
      }),
      mainEmailAddress: new AgencyUtils.Field({
        title: "Email",
        initialValue: service.mainEmailAddress,
        description:
          "Is there a general email address for this specific service? This should not be an email address for a specific person. (i.e. - foodpantry@organization.org)",
        placeholder: AgencyUtils.emailPlaceholder,
      }),
      phone_1: new AgencyUtils.Field({
        title: "Toll Free",
        initialValue: service.phone1,
        description: "What phone number should individuals use for this service?",
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      phone_2: new AgencyUtils.Field({
        title: "Hotline",
        initialValue: service.phone2,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      phone_3: new AgencyUtils.Field({
        title: "Business Line",
        initialValue: service.phone3,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      phone_4: new AgencyUtils.Field({
        title: "Fax",
        initialValue: service.phone4,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      phone_5: new AgencyUtils.Field({
        title: "TTY",
        initialValue: service.phone5,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      application_process: new AgencyUtils.Field({
        title: "Application Process",
        initialValue: service.applicationProcess,
        description: "What are the steps an individual should take to access this service?",
      }),
      fee_structure_source: new AgencyUtils.Field({
        title: "Fees",
        initialValue: service.feeStructureSource,
        description: "Are there any fees for this service?",
        inputType: "richtext",
      }),
      documents_required: new AgencyUtils.Field({
        title: "Documents Required",
        initialValue: service.documentsRequired,
      }),
      languages: new AgencyUtils.Field({
        title: "Languages Offered",
        initialValue: service.languages,
        inputType: "checkbox",
        picklist: AgencyUtils.languagesList,
      }),
    }),
  },
  co: {
    getAgencyParams: (agency) => {
      return {
        director_name: new AgencyUtils.Field({
          title: "Director's Full Name",
          initialValue: agency.directorName,
        }),
        director_position: new AgencyUtils.Field({
          title: "Director's Position",
          initialValue: agency.directorPosition,
        }),
        update_contact_name: new AgencyUtils.Field({
          title: "Update Contact Name",
          initialValue: agency.updateContactName,
          description:
            "If we have any questions about your agency or any of its services, who should we contact? This is for internal purposes only and will not be given to the public.",
        }),
        update_contact_email: new AgencyUtils.Field({
          title: "Update Contact Email",
          initialValue: agency.updateContactEmail,
          placeholder: AgencyUtils.emailPlaceholder,
        }),
        update_contact_phone: new AgencyUtils.Field({
          title: "Update Contact Phone",
          intialValue: agency.updateContactPhone,
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        county: new AgencyUtils.Field({
          title: "Agency County",
          initialValue: agency.county,
          description: "What county is your main location/headquarters in?",
          inputType: "select",
          picklist: StateAgencyUtils["co"].countiesList,
        }),
      };
    },
    getSiteParams: (site) => {
      const stateAgencyUtils = window[`${state}AgencyUtils`];

      let disabilitiesAccessList = AgencyUtils.disabilitiesAccessList;
      if (stateAgencyUtils && stateAgencyUtils.disabilitiesAccessList) {
        disabilitiesAccessList = stateAgencyUtils.disabilitiesAccessList;
      }

      let fieldParams = {
        // Need to copy the entire multifield since we do a shallow merge
        address: new AgencyUtils.MultiField("Physical Address", {
          address_1: new AgencyUtils.Field({
            title: "Physical Address 1",
            initialValue: site.address1,
          }),
          address_2: new AgencyUtils.Field({
            title: "Physical Address 2",
            initialValue: site.address2,
          }),
          city: new AgencyUtils.Field({
            title: "Physical Address City",
            initialValue: site.city,
          }),
          state: new AgencyUtils.Field({
            title: "Physical Address State",
            initialValue: site.state,
            inputType: "select",
            picklist: AgencyUtils.statesList,
          }),
          postal_code: new AgencyUtils.Field({
            title: "Physical Address Zip",
            initialValue: site.postalCode,
          }),
          county: new AgencyUtils.Field({
            title: "Physical Address County",
            initialValue: site.county,
            inputType: "select",
            picklist: StateAgencyUtils["co"].countiesList,
          }),
        }),
        // Need to copy the entire multifield since we do a shallow merge
        mailingAddress: new AgencyUtils.MultiField("Mailing Address", {
          mailing_address_1: new AgencyUtils.Field({
            title: "Mailing Address 1",
            initialValue: site.mailingAddress1,
          }),
          mailing_address_2: new AgencyUtils.Field({
            title: "Mailing Address 2",
            initialValue: site.mailingAddress2,
          }),
          mailing_city: new AgencyUtils.Field({
            title: "Mailing Address City",
            initialValue: site.mailingCity,
          }),
          mailing_state: new AgencyUtils.Field({
            title: "Mailing Address State",
            initialValue: site.mailingState,
            inputType: "select",
            picklist: AgencyUtils.statesList,
          }),
          mailing_postal_code: new AgencyUtils.Field({
            title: "Mailing Address Zip",
            initialValue: site.mailingPostalCode,
          }),
          mailing_county: new AgencyUtils.Field({
            title: "Mailing Address County",
            initialValue: site.mailingCounty,
            inputType: "select",
            picklist: StateAgencyUtils["co"].countiesList,
          }),
        }),
        wheelchair_accessibility: new AgencyUtils.Field({
          title: "Wheelchair Accessibility",
          initialValue: site.wheelchairAccess,
          description: "Is this location fully wheelchair accessible?",
          inputType: "select",
          picklist: AgencyUtils.wheelchairAccessList,
        }),
        status: new AgencyUtils.Field({
          title: "Location Status",
          initialValue: site.status,
          description: "Is this site still actively used to offer services?",
          inputType: "select",
          picklist: AgencyUtils.statusList,
        }),
        disabilities_access: new AgencyUtils.Field({
          title: "Additional Accessibility Information",
          initialValue: site.disabilitiesAccess || "", // empty string necessary for checkboxes
          description: `Are any of the following offered at this location: ${disabilitiesAccessList.join(", ")}?`,
          inputType: "checkbox",
          picklist: disabilitiesAccessList,
        }),
        phone_1: new AgencyUtils.Field({
          title: "Site Phone Number",
          initialValue: site.phone1,
          description: "What is this location's primary phone number?",
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        phone_2: new AgencyUtils.Field({
          title: "Other Site Phone Number",
          initialValue: site.phone2,
          description: "Is there another phone number individuals need for this location?",
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        phone_fax: new AgencyUtils.Field({
          title: "Fax Number",
          initialValue: site.phoneFax,
          placeholder: AgencyUtils.phonePlaceholder,
        }),
      };

      if (site.crisperEnrolled) {
        fieldParams = {
          ...fieldParams,
          ...{
            crisper_preferred_referral_method: new AgencyUtils.Field({
              title: "Preferred Referral Method",
              initialValue: site.preferredReferralMethod,
            }),
            crisper_referral_emails: new AgencyUtils.Field({
              title: "Referral Email Address",
              initialValue: site.referralEmails,
              placeholder: AgencyUtils.emailPlaceholder,
            }),
            crisper_referral_phone: new AgencyUtils.Field({
              title: "Referral Phone Number",
              initialValue: site.referralPhone,
              placeholder: AgencyUtils.phonePlaceholder,
            }),
            crisper_referral_fax: new AgencyUtils.Field({
              title: "Referral Fax",
              initialValue: site.referralFax,
              placeholder: AgencyUtils.phonePlaceholder,
            }),
            crisper_referral_sms: new AgencyUtils.Field({
              title: "Referral Text",
              initialValue: site.referralSms,
              placeholder: AgencyUtils.phonePlaceholder,
            }),
            virtual_program: new AgencyUtils.Field({
              title: "Virtual Program",
              initialValue: site.virtualProgram,
              description: "Is this listing for a virtual program?",
              inputType: "boolean",
            }),
          },
        };
      }

      return fieldParams;
    },
    getServiceParams: (service) => {
      return {
        custom_gender: new AgencyUtils.Field({
          title: "Genders Served",
          initialValue: service.customGender,
          description:
            "Please enter which of the following genders are eligibible to access this service: Female, Male, Transgender, Non Binary, No Restrictions",
          inputType: "checkbox",
          picklist: AgencyUtils.genderList,
          singleOption: "No Restrictions",
        }),
        custom_minimum_age: new AgencyUtils.Field({
          title: "Minimum Age Served",
          initialValue: service.customMinimumAge,
          placeholder: "Number from 0 to 120",
        }),
        custom_maximum_age: new AgencyUtils.Field({
          title: "Maximum Age Served",
          initialValue: service.customMaximumAge,
          placeholder: "Number from 0 to 120",
        }),
        languages: new AgencyUtils.Field({
          title: "Languages Spoken",
          initialValue: service.languages,
          description:
            "What languages is this service available in? Please note, this question is not asking for available documents/materials in different languages but spoken languages by the individuals providing this service.",
          inputType: "checkbox", // TODO: this should open a popup when it's too long
          picklist: AgencyUtils.languagesList,
        }),
        phone_hotline: new AgencyUtils.Field({
          title: "Hotline Phone Number",
          initialValue: service.phoneHotline,
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        phone_3: new AgencyUtils.Field({
          title: "TDD Phone Number",
          initialValue: service.phone3,
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        phone_2: new AgencyUtils.Field({
          title: "Service Other Phone Number",
          initialValue: service.phone2,
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        phone_fax: new AgencyUtils.Field({
          title: "Service Fax Number",
          initialValue: service.phoneFax,
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        phone_after_hours: new AgencyUtils.Field({
          title: "Emergency/After Hours Phone Number",
          initialValue: service.phoneAfterHours,
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        contact_name: new AgencyUtils.Field({
          title: "Service Contact Name",
          initialValue: service.contactName,
          description: "Is there one specific person that individuals must speak to in order to access this service?",
        }),
        contact_title: new AgencyUtils.Field({
          title: "Service Contact Title",
          initialValue: service.contactTitle,
          description: "If there is a Service Contact Name, what is that individual's position?",
        }),
        contact_email: new AgencyUtils.Field({
          title: "Service Contact Email",
          initialValue: service.contactEmail,
          description: "If there is a Service Contact Name, what is that individual's direct email?",
          placeholder: AgencyUtils.emailPlaceholder,
        }),
        documents_required: new AgencyUtils.Field({
          title: "Intake Requirements",
          initialValue: service.documentsRequired,
          description:
            "Are there any documents required to access this service? (i.e. - Photo ID, Copy of Social Security Card, etc.)",
          inputType: "richtext",
        }),
        coverage: new AgencyUtils.Field({
          title: "Service Area",
          initialValue: service.coverage,
          description:
            'What is the exact area of service for this program? Please use exact counties, cities or zip codes and refrain from vague descriptions such as "Southern", "Metro Area", or "and surrounding area", etc.',
        }),
        service_fees: new AgencyUtils.Field({
          title: "Service Fees",
          initialValue: service.serviceFees,
          description: "Are there any fees for this service?",
          inputType: "boolean",
        }),
        insurance_accepted: new AgencyUtils.Field({
          title: "Insurance Accepted",
          initialValue: service.insuranceAccepted,
          description:
            'Please list what types of insurance you accept for this service or enter "Not Applicable" if no insurance is needed or accepted.',
          inputType: "checkbox",
          picklist: AgencyUtils.insuranceList,
          singleOption: "Not Applicable",
        }),
        payment_notes: new AgencyUtils.Field({
          title: "Other Payment Information",
          initialValue: service.paymentNotes,
          description:
            "Is there any other information our clients should know about the payment system for this service? (i.e. - No one turned away for inability to pay, Scholarships available, Sliding scale fees available, etc.)",
        }),
        wait_list: new AgencyUtils.Field({
          title: "Wait List",
          initialValue: service.waitList,
          description:
            "If there is currently a wait list for this service, please enter what the approximate wait time is before an individual would be able to access the service.",
        }),
        immigrants_without_documentation: new AgencyUtils.Field({
          title: "Available to Immigrants w/o Documentation",
          initialValue: service.immigrantsWithoutDocumentation,
          description: "Is this service available to immigrants without documentation?",
          inputType: "boolean",
        }),
        display_start_date: new AgencyUtils.Field({
          title: "Display Start Date",
          initialValue: service.displayStartDate,
          description:
            "If this service should not be displayed currently, what date in the future should we start to display this information?",
          placeholder: AgencyUtils.datePlaceholder,
        }),
        display_end_date: new AgencyUtils.Field({
          title: "Display End Date",
          initialValue: service.displayEndDate,
          description:
            "If this service has a specific end date, what date in the future should we stop displaying this information?",
          placeholder: AgencyUtils.datePlaceholder,
        }),
        tips: new AgencyUtils.Field({
          title: "Helpful Tips",
          initialValue: service.tips,
          description: "Is there any other information that would be helpful to individuals accessing this service?",
          inputType: "richtext",
        }),
        status: new AgencyUtils.Field({
          title: "Service Status",
          initialValue: service.status,
          description:
            'Please identify if this service is still available by entering "Active" or if the service has been discontinued by entering "Inactive".',
          inputType: "select",
          picklist: AgencyUtils.statusList,
        }),
      };
    },
  },
  ct: {
    getAgencyParams: (agency) => {
      return {
        address: new AgencyUtils.MultiField("Physical Address", {
          physical_street_1: new AgencyUtils.Field({
            title: "Physical Address 1",
            initialValue: agency.street1,
          }),
          physical_street_2: new AgencyUtils.Field({
            title: "Physical Address 2",
            initialValue: agency.street2,
          }),
          physical_city: new AgencyUtils.Field({
            title: "Physical Address City",
            initialValue: agency.city,
          }),
          physical_state: new AgencyUtils.Field({
            title: "Physical Address State",
            initialValue: agency.state,
            inputType: "select",
            picklist: AgencyUtils.statesList,
          }),
          physical_postal_code: new AgencyUtils.Field({
            title: "Physical Address Zip",
            initialValue: agency.postalCode,
          }),
        }),
        mailingAddress: new AgencyUtils.MultiField("Mailing Address", {
          mailing_street_1: new AgencyUtils.Field({
            title: "Mailing Address 1",
            initialValue: agency.mailingStreet1,
          }),
          mailing_street_2: new AgencyUtils.Field({
            title: "Mailing Address 2",
            initialValue: agency.mailingStreet2,
          }),
          mailing_city: new AgencyUtils.Field({
            title: "Mailing Address City",
            initialValue: agency.mailingCity,
          }),
          mailing_state: new AgencyUtils.Field({
            title: "Mailing Address State",
            initialValue: agency.mailingState,
            inputType: "select",
            picklist: AgencyUtils.statesList,
          }),
          mailing_postal_code: new AgencyUtils.Field({
            title: "Mailing Address Zip",
            initialValue: agency.mailingPostalCode,
          }),
        }),
      };
    },
    getSiteParams: (site) => {
      const stateAgencyUtils = window[`${state}AgencyUtils`];

      let disabilitiesAccessList = AgencyUtils.disabilitiesAccessList;
      if (stateAgencyUtils && stateAgencyUtils.disabilitiesAccessList) {
        disabilitiesAccessList = stateAgencyUtils.disabilitiesAccessList;
      }

      return {
        mainEmailAddress: new AgencyUtils.Field({
          title: "Email",
          initialValue: site.email,
          placeholder: AgencyUtils.emailPlaceholder,
        }),
        website: new AgencyUtils.Field({
          title: "Website",
          initialValue: site.website,
        }),
        languages: new AgencyUtils.Field({
          title: "Languages Offered",
          initialValue: site.languages,
          inputType: "checkbox", // TODO: this should open a popup when it's too long
          picklist: AgencyUtils.languagesList,
        }),
        disabilities_access: new AgencyUtils.Field({
          title: "Additional Accessibility Information",
          initialValue: site.disabilitiesAccess || "", // empty string necessary for checkboxes
          description: `Are any of the following offered at this location: ${disabilitiesAccessList.join(", ")}?`,
          inputType: "checkbox",
          picklist: disabilitiesAccessList,
        }),
        phone_1: new AgencyUtils.Field({
          title: "Site Phone Number",
          initialValue: site.phone1,
          description: "What is this location's primary phone number?",
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        phone_2: new AgencyUtils.Field({
          title: "Other Site Phone Number",
          initialValue: site.phone2,
          description: "Is there another phone number individuals need for this location?",
          placeholder: AgencyUtils.phonePlaceholder,
        }),
        phone_fax: new AgencyUtils.Field({
          title: "Fax Number",
          initialValue: site.phoneFax,
          placeholder: AgencyUtils.phonePlaceholder,
        }),
      };
    },
    getServiceParams: (service) => ({
      custom_gender: new AgencyUtils.Field({
        title: "Genders Served",
        initialValue: service.customGender,
        description:
          "Please enter which of the following genders are eligibible to access this service: Female, Male, Transgender, Non Binary, No Restrictions",
        inputType: "checkbox",
        picklist: AgencyUtils.genderList,
        singleOption: "No Restrictions",
      }),
      custom_minimum_age: new AgencyUtils.Field({
        title: "Minimum Age Served",
        initialValue: service.customMinimumAge,
        placeholder: "Number from 0 to 120",
      }),
      custom_maximum_age: new AgencyUtils.Field({
        title: "Maximum Age Served",
        initialValue: service.customMaximumAge,
        placeholder: "Number from 0 to 120",
      }),
    }),
  },
};

export const AgencyParams = {
  getAgencyParams: (agency) => {
    const stateAgencyParams = StateAgencyParams[state];
    const localeFields =
      stateAgencyParams && stateAgencyParams.getAgencyParams && stateAgencyParams.getAgencyParams(agency);

    return {
      name: new AgencyUtils.Field({
        title: "Agency Name",
        initialValue: agency.name,
      }),
      alternate_name: new AgencyUtils.Field({
        title: "Agency Aliases",
        initialValue: agency.agencyAliases,
        description: "Are there any other names individuals may know your agency as?",
      }),
      description: new AgencyUtils.Field({
        title: "Agency Description",
        initialValue: agency.description,
        description: "How would you describe what your agency does in a couple of sentences?",
        inputType: "richtext",
      }),
      phone: new AgencyUtils.Field({
        title: "Agency Main Phone Number",
        initialValue: agency.phone,
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      mainEmailAddress: new AgencyUtils.Field({
        title: "Agency General Email",
        initialValue: agency.email,
        placeholder: AgencyUtils.emailPlaceholder,
      }),
      website: new AgencyUtils.Field({
        title: "Agency Website",
        initialValue: agency.website,
      }),
      hours: new AgencyUtils.Field({
        title: "Agency Hours of Operation",
        initialValue: agency.hoursOfOperation,
        description: "What are the general office hours for the agency?",
      }),
      legal_status: new AgencyUtils.Field({
        title: "Organization Type",
        initialValue: agency.organizationType,
        inputType: "select",
        picklist: (() => {
          if (stateAgencyUtils && stateAgencyUtils.organizationTypeList) {
            return stateAgencyUtils.organizationTypeList;
          } else {
            return AgencyUtils.organizationTypeList;
          }
        })(),
      }),
      ...localeFields,
    };
  },
  getSiteParams: (site) => {
    const stateAgencyParams = StateAgencyParams[state];
    const localeFields = stateAgencyParams && stateAgencyParams.getSiteParams && stateAgencyParams.getSiteParams(site);

    let fieldParams = {
      alternate_name: new AgencyUtils.Field({
        title: "Site Name",
        initialValue: site.aliases,
        description: "What is the name of this location/building?",
      }),
      address: new AgencyUtils.MultiField("Physical Address", {
        address_1: new AgencyUtils.Field({
          title: "Physical Address 1",
          initialValue: site.address1,
        }),
        address_2: new AgencyUtils.Field({
          title: "Physical Address 2",
          initialValue: site.address2,
        }),
        city: new AgencyUtils.Field({
          title: "Physical Address City",
          initialValue: site.city,
        }),
        state: new AgencyUtils.Field({
          title: "Physical Address State",
          initialValue: site.state,
          inputType: "select",
          picklist: AgencyUtils.statesList,
        }),
        postal_code: new AgencyUtils.Field({
          title: "Physical Address Zip",
          initialValue: site.postalCode,
        }),
      }),
      mailingAddress: new AgencyUtils.MultiField("Mailing Address", {
        mailing_address_1: new AgencyUtils.Field({
          title: "Mailing Address 1",
          initialValue: site.mailingAddress1,
        }),
        mailing_address_2: new AgencyUtils.Field({
          title: "Mailing Address 2",
          initialValue: site.mailingAddress2,
        }),
        mailing_city: new AgencyUtils.Field({
          title: "Mailing Address City",
          initialValue: site.mailingCity,
        }),
        mailing_state: new AgencyUtils.Field({
          title: "Mailing Address State",
          initialValue: site.mailingState,
          inputType: "select",
          picklist: AgencyUtils.statesList,
        }),
        mailing_postal_code: new AgencyUtils.Field({
          title: "Mailing Address Zip",
          initialValue: site.mailingPostalCode,
        }),
      }),
      hours_of_operation: new AgencyUtils.Field({
        title: "Location Hours",
        initialValue: site.hoursOfOperation,
        description: "What are the general office hours for this location?",
      }),
      ...localeFields,
    };
    return fieldParams;
  },
  getServiceParams: (service) => {
    const stateAgencyParams = StateAgencyParams[state];
    const localeFields =
      stateAgencyParams && stateAgencyParams.getServiceParams && stateAgencyParams.getServiceParams(service);

    return {
      alternate_name: new AgencyUtils.Field({
        title: "Other Service Name",
        initialValue: service.alternateName,
      }),
      description: new AgencyUtils.Field({
        title: "Description of Service",
        initialValue: service.description,
        description:
          "How would you describe this service? 2-1-1 lists descriptions as clearly and concisely as possible while trying to keep the description at a sixth grade reading level. We may edit the description provided in order to make it fit our formatting guidelines.",
        inputType: "richtext",
      }),
      website: new AgencyUtils.Field({
        title: "Service Web Address",
        initialValue: service.website,
        description: "Is there a web page for information related to this service?",
      }),
      program_hours_of_operation: new AgencyUtils.Field({
        title: "Service Hours of Operation",
        initialValue: service.programHoursOfOperation,
      }),
      mainEmailAddress: new AgencyUtils.Field({
        title: "Service General Email",
        initialValue: service.mainEmailAddress,
        description:
          "Is there a general email address for this specific service? This should not be an email address for a specific person. (i.e. - foodpantry@organization.org)",
        placeholder: AgencyUtils.emailPlaceholder,
      }),
      phone_1: new AgencyUtils.Field({
        title: "Service Phone Number",
        initialValue: service.phone1,
        description: "What phone number should individuals use for this service?",
        placeholder: AgencyUtils.phonePlaceholder,
      }),
      application_process: new AgencyUtils.Field({
        title: "Intake Procedure",
        initialValue: service.applicationProcess,
        description: "What are the steps an individual should take to access this service?",
      }),
      eligibility: new AgencyUtils.Field({
        title: "Eligibility",
        initialValue: service.eligibility,
        description:
          "Are there any restrictions on who is eligibile for this service? Please be as specific as possible.",
        inputType: "richtext",
      }),
      custom_gender: new AgencyUtils.Field({
        title: "Genders Served",
        initialValue: service.customGender,
        description:
          "Please enter which of the following genders are eligibible to access this service: Female, Male, Transgender, Non Binary, No Restrictions",
        inputType: "checkbox",
        picklist: AgencyUtils.genderList,
        singleOption: "No Restrictions",
      }),
      custom_minimum_age: new AgencyUtils.Field({
        title: "Minimum Age Served",
        initialValue: service.customMinimumAge,
        placeholder: "Number from 0 to 120",
      }),
      custom_maximum_age: new AgencyUtils.Field({
        title: "Maximum Age Served",
        initialValue: service.customMaximumAge,
        placeholder: "Number from 0 to 120",
      }),
      fee_structure_source: new AgencyUtils.Field({
        title: "Fee Description",
        initialValue: service.feeStructureSource,
        description:
          "If there are fees associated with this service, what are the fees for and what are the approximate costs of them?",
        inputType: "richtext",
      }),
      ...localeFields,
    };
  },
};
