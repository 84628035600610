import React, { useState } from "react";

import Input from "./Input";

export default {
  title: "Agency/Input",
  component: Input,
};

// Dropdown components need a bit of extra height in order to render their children
// without cropping. This just gives them a bit of extra space to expand.
const DropdownWrapper = ({ children }) => <div style={{ height: "600px" }}>{children}</div>;

const Template = (args) => {
  const [fieldsState, setFieldsState] = useState({ boolean: true, select: null });
  const stateProps = { fieldsState, setFieldsState };
  const fullArgs = { ...args, ...stateProps };
  return (
    <DropdownWrapper>
      <Input {...fullArgs} />
    </DropdownWrapper>
  );
};

const form = {
  errorFields: new Map(),
  validateField: (name, content) => {
    return true;
  },
};

export const RichtextInput = Template.bind({});
RichtextInput.args = {
  type: "richtext",
  name: "richtext",
  placeholder: "richtext",
  ...form,
};

export const TextareaInput = Template.bind({});
TextareaInput.args = {
  type: "textarea",
  name: "textarea",
  placeholder: "textarea",
  ...form,
};

export const TextInput = Template.bind({});
TextInput.args = {
  type: "text",
  name: "text",
  placeholder: "text",
  ...form,
};

export const BooleanInput = Template.bind({});
BooleanInput.args = {
  type: "boolean",
  name: "boolean",
  ...form,
};

export const SelectInput = Template.bind({});
SelectInput.args = {
  type: "select",
  name: "select",
  picklist: ["Harry", "Hermione", "Ron"],
  ...form,
};

export const Checkboxes = Template.bind({});
Checkboxes.args = {
  type: "checkbox",
  name: "checkbox",
  picklist: ["Transfiguration", "Muggle Studies", "Charms"],
  ...form,
};
