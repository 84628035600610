import React from "react";

function COFooter() {
  return (
    <footer className="site-footer co">
      <div className="wrap">
        <nav className="navigation-footer">
          <div className="footer-link-headings">
            <p>
              Need other resources such as food, housing, financial assistance, transportation, or
              immigration/naturalization?
            </p>
            <p>
              <strong>Dial 2-1-1</strong>
              <span>or visit one of the pages below.</span>
            </p>
          </div>
          <ul className="menu-nav">
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/tax-assistance/">
                Tax Assistance
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/housing-and-shelter/">
                Housing and Shelter
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/food-assistance">
                Food Assistance
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/legal">
                Legal
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/employment">
                Employment
              </a>
            </li>
          </ul>
          <ul className="menu-nav">
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/health-services">
                Health Services
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/crisis-and-emergency">
                Crisis and Emergency
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/mental-health-and-addiction">
                Mental Health and Addiction
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/children-and-youth-with-special-needs/">
                Children and Youth with Special Needs
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/pregnant-new-parents/">
                Pregnant and New Parents
              </a>
            </li>
          </ul>
          <ul className="menu-nav">
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/child-care-and-education">
                Child Care and Education
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/aging-and-disability">
                Aging and Disability
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/immigrant-and-refugee-services/">
                Immigrants and Refugees
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.211colorado.org/basic-needs-and-financial">
                Basic Needs and Financial
              </a>
            </li>
          </ul>
          <div className="icon-co logo-unitedway-co"></div>
        </nav>
        <div className="footer-bottom">
          <ul className="menu-social">
            <li className="nav-item">
              <a className="nav-link" href="https://www.facebook.com/unitedwaydenver" aria-label="facebook link">
                <i className="ico ico-facebook"></i>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://twitter.com/unitedwaydenver" aria-label="twitter link">
                <i className="ico ico-twitter"></i>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://www.youtube.com/user/MileHighUnitedWay" aria-label="youtube link">
                <i className="ico ico-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default COFooter;
