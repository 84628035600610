import PropTypes from "prop-types";
import Category from "./Category";
import createReactClass from "create-react-class";

const ResourceListEmpty = createReactClass({
  propTypes: {
    taxonomies: PropTypes.arrayOf(PropTypes.object),
    helpUrl: PropTypes.string,
    responseId: PropTypes.number,
    removeCategory: PropTypes.func,
    onSearch: PropTypes.func,
  },

  onClick: function () {
    const { taxonomies, responseId } = this.props;
    this.props.onSearch(taxonomies, responseId);
  },

  render: function () {
    const { taxonomies, removeCategory, helpUrl } = this.props;
    return (
      <div className="resource-list-empty">
        <h4>Begin Adding Resources.</h4>
        <p>To add resources, review your search categories and click "Add Resources"</p>
        <div className="selections">
          {taxonomies.map((taxonomy) => {
            return (
              <Category
                categoryText={taxonomy.name}
                key={taxonomy.id}
                taxonomyCode={taxonomy.code}
                removeCategory={removeCategory}
              />
            );
          })}
        </div>
        <div className="resource-list-empty-footer">
          <a onClick={this.onClick} className="btn btn-primary-screener pointer">
            Add Resources
          </a>
          {helpUrl && (
            <a href={helpUrl} target="_blank">
              Learn more about these categories
            </a>
          )}
        </div>
      </div>
    );
  },
});

export default ResourceListEmpty;
