import PropTypes from "prop-types";

export default function AccountNavBar({ selection, appPaths }) {
  const commonClassName = "my211-btn";

  const classes = {
    agency: commonClassName,
    resources: commonClassName,
    screeners: commonClassName,
    referrals: commonClassName,
    settings: commonClassName,
  };

  classes[selection] += " selected";

  return (
    <div className="my211-nav-bar desktop-only">
      {UW211.IS_NON_DENIED_AGENCY_USER && (
        <div className={classes.agency}>
          <a className="my211-nav-btn" href={appPaths.myAgencyData}>
            Agency
          </a>
        </div>
      )}
      <div className={classes.resources}>
        <a className="my211-nav-btn" href={appPaths.resourceLists}>
          Resource Lists
        </a>
      </div>
      {UW211.ORGANIZATION.userFeatures && UW211.ORGANIZATION.userFeatures.screeners && (
        <div className={classes.screeners}>
          <a className="my211-nav-btn" href={appPaths.screeners}>
            Screeners
          </a>
        </div>
      )}
      {UW211.ORGANIZATION.userFeatures && UW211.ORGANIZATION.userFeatures.referrals && (
        <div className={classes.referrals}>
          <a className="my211-nav-btn" href={appPaths.myReferrals}>
            Service Requests
          </a>
        </div>
      )}
      <div className={classes.settings}>
        <a className="my211-nav-btn" href={appPaths.account}>
          Account Settings
        </a>
      </div>
    </div>
  );
}

AccountNavBar.propTypes = {
  selection: PropTypes.oneOf(["agency", "resources", "screeners", "referrals", "settings"]).isRequired,
  appPaths: PropTypes.shape({
    myAgencyData: PropTypes.string,
    resourceLists: PropTypes.string,
    screeners: PropTypes.string,
    myReferrals: PropTypes.string,
    account: PropTypes.string,
  }),
};

AccountNavBar.defaultProps = {
  appPaths: {
    myAgencyData: "/my211/agency_data",
    resourceLists: "/my211/resource-lists",
    screeners: "/my211/screeners",
    myReferrals: "/my211/referrals",
    account: "/user/edit",
  },
};
