import PropTypes from "prop-types";
import AccountNavBar from "../AccountNavBar";
import AgencyNav from "./AgencyNav";
import AgencyChange from "./AgencyChange";
import RelationList from "./RelationList";
import UpdatesTable from "./UpdatesTable";
import { AgencyParams } from "./agency-params";

export default function AgencySite({ site, servicesNotAtSite, updates = [] }) {
  const { number, crisperEnrolled } = site;

  const fieldParams = AgencyParams.getSiteParams(site);

  return (
    <div className="agencies-container row">
      <div className="agencies-content">
        <AccountNavBar selection="agency" />
        <div className="agencies-row">
          <AgencyNav selection="sites" />
          <div className="agencies-data-container">
            <div>
              <ol className="breadcrumb top breadcrumb-new">
                <li>
                  <a href="/my211/agency_sites">All Sites</a>
                </li>
                <li className="active">{fieldParams.address.subfields.address_1.initialValue || number}</li>
              </ol>
            </div>
            <AgencyChange type="site" id={number} fieldParams={fieldParams} />
            <RelationList id={number} listType="services" addRelationList={servicesNotAtSite} />
            <UpdatesTable updates={updates} />
          </div>
        </div>
      </div>
    </div>
  );
}

AgencySite.propTypes = {
  site: PropTypes.shape({
    phone1: PropTypes.string,
    phone2: PropTypes.string,
    phoneFax: PropTypes.string,
    email: PropTypes.string,
    website: PropTypes.string,
    wheelchairAccess: PropTypes.string,
    disabilitiesAccess: PropTypes.string,
    languages: PropTypes.string,
    status: PropTypes.string,
    hoursOfOperation: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    mailingAddress1: PropTypes.string,
    mailingAddress2: PropTypes.string,
    mailingCity: PropTypes.string,
    mailingCounty: PropTypes.string,
    mailingState: PropTypes.string,
    mailingPostalCode: PropTypes.string,
    number: PropTypes.number.isRequired,
    aliases: PropTypes.string,
    crisperEnrolled: PropTypes.boolean,
    preferredReferralMethod: PropTypes.string,
    referralEmails: PropTypes.string,
    referralPhone: PropTypes.string,
    referralFax: PropTypes.string,
    referralSms: PropTypes.string,
    virtualProgram: PropTypes.bool,
  }),
  servicesNotAtSite: PropTypes.array,
  updates: PropTypes.array,
};
