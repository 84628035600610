import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import Answer from "../Answer";

const QuestionAnswer = createReactClass({
  propTypes: {
    questionAnswer: PropTypes.object,
  },

  render: function () {
    const { questionAnswer } = this.props;

    return (
      <div className="question-answer">
        <p>{questionAnswer.question}</p>
        {questionAnswer.answers.map((answer, index) => {
          return <Answer key={index} label={answer} questionType={questionAnswer.type} answerId={index} />;
        })}
      </div>
    );
  },
});

export default QuestionAnswer;
