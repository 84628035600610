import Computer from "../../icons/Computer";
import ListIcon from "../../icons/List";
import Phone from "../../icons/Phone";
import PinDrop from "../../icons/PinDrop";
import React from "react";
import ReactUtils from "../../react-utils";

import type { ProgramAtSiteData } from "./types";

function ProgramAtSiteCard({
  name,
  address,
  description,
  eligibility,
  hours,
  phoneNumber,
  websiteUrl,
  slug,
}: ProgramAtSiteData) {

  const addressString = ReactUtils.formatAddressString(address);

  return (
    <div className="program-at-site-card">
      <div className="title-address">
        <h3>
          <a href={slug} className="name-link">
            {name}
          </a>
        </h3>
        <a
          href={`https://www.google.com/maps/search/${encodeURIComponent(addressString)}`}
          className="map-link"
          target="_blank"
          rel="noreferrer"
        >
          <PinDrop />
          <span>{addressString}</span>
        </a>
      </div>
      <div className="site-info">
        <p dangerouslySetInnerHTML={{ __html: description }} />
        {eligibility && (
          <div className="eligibility">
            <b>Eligibility: </b>
            {eligibility}
          </div>
        )}
        {hours && (
          <div className="hours">
            <b>Hours: </b>
            {hours}
          </div>
        )}
      </div>
      <div className="contact-info">
        {phoneNumber && (
          <a href={`tel:${phoneNumber}`}>
            <Phone />
            {phoneNumber}
          </a>
        )}

        {websiteUrl && (
          <a href={websiteUrl} target="_blank" rel="noreferrer">
            <Computer />
            View Website
          </a>
        )}
      </div>
      <div className="actions">
        <a href={slug}>More details &rarr;</a>
      </div>
    </div>
  );
}

export default ProgramAtSiteCard;
