import React from "react";
import PropTypes from "prop-types";
import ResourceList from "./ResourceList";
import ResourceListEmpty from "./ResourceListEmpty";
import ResponseNote from "./ResponseNote";
import createReactClass from "create-react-class";

const ResourceListContainer = createReactClass({
  propTypes: {
    questionAnswer: PropTypes.object,
    removeCategory: PropTypes.func,
    onSearch: PropTypes.func,
    signedIn: PropTypes.bool,
    changeNote: PropTypes.func,
    toggleModalState: PropTypes.func,
    isModalDisplayed: PropTypes.bool,
    user: PropTypes.object,
    surveyName: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  },

  onClick: function () {
    const { questionAnswer } = this.props;
    const { taxonomies, responseId, personalInfo } = questionAnswer;
    const resourceList = personalInfo && personalInfo.resource_list;
    this.props.onSearch(taxonomies, responseId, resourceList);
  },

  render: function () {
    const {
      questionAnswer,
      onSearch,
      removeCategory,
      signedIn,
      changeNote,
      toggleModalState,
      isModalDisplayed,
      user,
      surveyName,
      category,
      token,
    } = this.props;
    const { taxonomies, personalInfo, responseId } = questionAnswer;
    const hasTaxonomies = taxonomies.length > 0;
    const status = personalInfo ? personalInfo.status : undefined;
    const note = personalInfo ? personalInfo.note : "";
    const resourceList = personalInfo && personalInfo.resource_list ? personalInfo.resource_list : {};

    return (
      <React.Fragment>
        {hasTaxonomies && !_.isEmpty(resourceList) && (
          <React.Fragment>
            <ResourceList
              resourceList={resourceList}
              toggleModalState={toggleModalState}
              isModalDisplayed={isModalDisplayed}
              user={user}
              surveyName={surveyName}
              category={category}
              token={token}
              responseId={responseId}
            />
            <div className="resource-list-footer">
              <a onClick={this.onClick} className="btn btn-primary-screener pointer">
                Add Resources
              </a>
              {signedIn && <ResponseNote text={note} responseId={responseId} changeNote={changeNote} />}
            </div>
          </React.Fragment>
        )}
        {hasTaxonomies && _.isEmpty(resourceList) && (
          <React.Fragment>
            <ResourceListEmpty
              taxonomies={taxonomies}
              onSearch={onSearch}
              removeCategory={removeCategory}
              helpUrl={questionAnswer.helpUrl}
              responseId={responseId}
            />
            {signedIn && (
              <div className="resource-list-footer">
                <ResponseNote text={note} responseId={responseId} changeNote={changeNote} />
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  },
});

export default ResourceListContainer;
