import React from "react";

export default function PinDrop() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_267_2268"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_267_2268)">
        <path
          d="M8 11.2C9.15556 10.2778 10.0278 9.39156 10.6167 8.54133C11.2056 7.69156 11.5 6.87778 11.5 6.1C11.5 5.44444 11.3804 4.886 11.1413 4.42467C10.9027 3.96378 10.6084 3.59156 10.2587 3.308C9.90844 3.02489 9.52778 2.81933 9.11667 2.69133C8.70556 2.56378 8.33333 2.5 8 2.5C7.66667 2.5 7.29444 2.56378 6.88333 2.69133C6.47222 2.81933 6.09178 3.02489 5.742 3.308C5.39178 3.59156 5.09711 3.96378 4.858 4.42467C4.61933 4.886 4.5 5.44444 4.5 6.1C4.5 6.87778 4.79444 7.69156 5.38333 8.54133C5.97222 9.39156 6.84444 10.2778 8 11.2ZM8 12.4667C6.48889 11.3333 5.36111 10.2416 4.61667 9.19133C3.87222 8.14156 3.5 7.11111 3.5 6.1C3.5 5.34444 3.63622 4.68044 3.90867 4.108C4.18067 3.536 4.53067 3.05556 4.95867 2.66667C5.38622 2.27778 5.86956 1.98622 6.40867 1.792C6.94733 1.59733 7.47778 1.5 8 1.5C8.52222 1.5 9.05289 1.59733 9.592 1.792C10.1307 1.98622 10.6138 2.27778 11.0413 2.66667C11.4693 3.05556 11.8196 3.536 12.092 4.108C12.364 4.68044 12.5 5.34444 12.5 6.1C12.5 7.11111 12.1278 8.14156 11.3833 9.19133C10.6389 10.2416 9.51111 11.3333 8 12.4667ZM8 7.16667C8.32222 7.16667 8.59733 7.05289 8.82533 6.82533C9.05289 6.59733 9.16667 6.32222 9.16667 6C9.16667 5.67778 9.05289 5.40267 8.82533 5.17467C8.59733 4.94711 8.32222 4.83333 8 4.83333C7.67778 4.83333 7.40267 4.94711 7.17467 5.17467C6.94711 5.40267 6.83333 5.67778 6.83333 6C6.83333 6.32222 6.94711 6.59733 7.17467 6.82533C7.40267 7.05289 7.67778 7.16667 8 7.16667ZM3.5 14.5V13.5H12.5V14.5H3.5Z"
          fill="#125790"
        />
      </g>
    </svg>
  );
}
