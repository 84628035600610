import React from "react";
import PropTypes from "prop-types";
import ReactUtils from "./../../react-utils";

export default function ListItem({ type, address1, address2, city, state, postalCode, number, name }) {
  return (
    <div className="agency-container agency-list-container">
      <div className="agency-list-address-container">
        {type === "service" && <h4 className="agency-title">{name || <em>No Name Provided</em>}</h4>}
        {type === "site" && (
          <React.Fragment>
            <h4 className="agency-title agency-margin-bottom">{address1 || <em>No Address Provided</em>}</h4>
            {ReactUtils.formatAgencyDataAddress(address2, city, state, postalCode)}
          </React.Fragment>
        )}
      </div>
      <a className="btn btn-default" href={`/my211/agency_${type}s/${number}`}>
        {`View ${type.charAt(0).toUpperCase()}${type.slice(1)} Information`}
      </a>
    </div>
  );
}

ListItem.propTypes = {
  type: PropTypes.string.isRequired,
  site: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    number: PropTypes.number.isRequired,
  }),
  program: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.number.isRequired,
  }),
};
