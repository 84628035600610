import React, { useState } from "react";
import PropTypes from "prop-types";
import ShareResourceList from "./ShareResourceList";
import { ResourceListsContext } from "./../contexts/ResourceListsContext";

export default function ResourceListItem({ list }) {
  const { updateListName, deleteList } = React.useContext(ResourceListsContext);

  const [formDisplayed, toggleFormDisplay] = useState(false);
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const [resourceName, setResourceName] = useState(list.name);

  function updateResourceListName(event) {
    event.preventDefault();
    event.stopPropagation();

    updateListName(list.hashid, resourceName).then(() => toggleFormDisplay(false));
  }

  function deleteResourceList() {
    if (confirm("Are you sure you want to remove this list?")) deleteList(list.hashid);
  }

  function formatDate(dateString) {
    return dateString.split("T")[0];
  }

  function handleChange(event) {
    setResourceName(event.target.value);
  }

  function toggleModalDisplay(visible) {
    setModalDisplayed(visible);
    if (!visible) {
      document.body.className = document.body.className.replace(/\s+modal-open/, "");

      const backdrop = document.getElementsByClassName("modal-backdrop")[0];
      if (backdrop) backdrop.remove();
    }
  }

  return (
    <div className="search-result card-highlight">
      <div>
        <ShareResourceList
          list={list}
          hidePrintFeature
          modalDisplayed={modalDisplayed}
          onModalClose={() => toggleModalDisplay(false)}
        />
      </div>

      <div className="actions">
        <div className="action small-label">
          <a
            className="pointer share"
            data-target={`#shareListModal${list.hashid}`}
            data-toggle="modal"
            type="button"
            href="#"
            onClick={() => toggleModalDisplay(true)}
          >
            <i className="ico ico-share" />
            {" Share List"}
          </a>
        </div>

        {!formDisplayed && (
          <React.Fragment>
            <div className="action small-label">
              <a className="remove-list" href="#" onClick={deleteResourceList}>
                <i className="glyphicon glyphicon-trash" />
                {" Delete"}
              </a>
            </div>

            <div className="action small-label">
              <a className="rename-list" href="#" onClick={() => toggleFormDisplay(true)}>
                <i className="glyphicon glyphicon-pencil" />
                {" Rename"}
              </a>
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="resource-list-details-container">
        <div className="resource-list-details">
          {!formDisplayed && <h4>{list.name}</h4>}
          {formDisplayed && (
            <form className="resource-list-form" onSubmit={updateResourceListName}>
              <input className="input" name="Name" value={resourceName} onChange={handleChange} />
              <button className="btn btn-primary resource-list-name-update">Update</button>
            </form>
          )}
          {list.items ? list.items.length : 0} Resources, Created on {formatDate(list.created_at)}
        </div>
      </div>

      <a className="btn btn-primary more" href={`resource-lists/${list.hashid}`}>
        Open List
      </a>
    </div>
  );
}

ResourceListItem.propTypes = {
  list: PropTypes.object.isRequired,
};
