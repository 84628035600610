import React from "react";
import PropTypes from "prop-types";
import ReactUtils from "./../react-utils";
import { AppDataContext } from "./../contexts/AppDataContext";
import { ResourceListsContext } from "./../contexts/ResourceListsContext";

export default function ShareResourceList({ list, hidePrintFeature, programSiteId, modalDisplayed, onModalClose }) {
  const { user, appPaths } = React.useContext(AppDataContext);
  const { shareList } = React.useContext(ResourceListsContext);

  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [client, setClient] = React.useState("");
  const [comments, setComments] = React.useState("");
  const [reply_to_me, toggleReply] = React.useState(true);

  const [confirmation, setConfirmation] = React.useState("");
  const [error, setError] = React.useState("");
  const [linkCopied, setLinkCopied] = React.useState("");

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const confirmationElement = React.useRef();

  const url = `${window.location.origin}${appPaths.sharedResourceList}/${list.hashid}`;

  function copyLink() {
    clipboard.writeText(url).then(() => {
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000);
    });
  }

  function shareResourceList() {
    if (!validateAndWarn()) return;
    onModalClose();

    shareList({
      id: list.hashid,
      email,
      phone,
      client,
      comments,
      reply_to_me,
      url,
    })
      .then(() => {
        setConfirmation("You should receive your resource list shortly. Thank you for using 2-1-1.");

        setTimeout(() => setConfirmation(""), 6000);
      })
      .catch(() => {
        setConfirmation("Your resource list couldn't be sent. Please try again.");
        confirmationElement.current.style.color = "red";

        setTimeout(() => setConfirmation(""), 6000);
      });
  }

  function validateAndWarn() {
    if (!email && !phone) {
      setError("Please provide either an email address or a phone number.");
      return false;
    }

    if (email) {
      if (!ReactUtils.validateEmail(email)) {
        setError("Email address is invalid.");
        return false;
      }
    }

    if (phone) {
      if (!ReactUtils.validatePhoneNumber(phone)) {
        setError("Phone number must be 10 digits.");
        return false;
      }
    }

    setError("");
    resetForm("");
    return true;
  }

  function resetForm() {
    setEmail("");
    setPhone("");
    setClient("");
    setComments("");
  }

  return (
    <React.Fragment>
      {confirmation && (
        <div className="share-list-confirmation open" ref={confirmationElement}>
          {confirmation}
        </div>
      )}

      {modalDisplayed && (
        <div
          id={`shareListModal${list.hashid}`}
          className="modal share-modal"
          role="dialog"
          tabIndex={-1}
          style={{ marginTop: "6em", zIndex: 9999 }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="actions">
                  {linkCopied ? (
                    <button className="btn copy-link-confirmation">
                      <i className="glyphicon glyphicon-check" />
                      Link copied
                    </button>
                  ) : (
                    <button className="btn copy-link-button" onClick={copyLink}>
                      <i className="glyphicon glyphicon-copy" />
                      Copy link
                    </button>
                  )}

                  {!hidePrintFeature && (
                    <button
                      className="btn"
                      name="print"
                      type="button"
                      data-dismiss="modal"
                      onClick={() => window.print()}
                    >
                      <i className="glyphicon glyphicon-print" />
                      Print List
                    </button>
                  )}
                </div>

                <h4>Share resource list</h4>
                {error && <div className="share-error">{error}</div>}
              </div>

              <ul className="nav nav-tabs" role="tablist">
                <li className="active" role="presentation">
                  <a
                    aria-controls={`share-email-${list.hashid}`}
                    data-toggle="tab"
                    role="tab"
                    href={`#share-email-${list.hashid}`}
                  >
                    E-mail
                  </a>
                </li>

                {UW211.ORGANIZATION && UW211.ORGANIZATION.flags.enableSMS && (
                  <li role="presentation">
                    <a
                      aria-controls={`share-text-${list.hashid}`}
                      data-toggle="tab"
                      role="tab"
                      href={`#share-text-${list.hashid}`}
                    >
                      Text message
                    </a>
                  </li>
                )}
              </ul>

              <div className="tab-content">
                <div id={`share-email-${list.hashid}`} className="tab-pane active" role="tabpanel">
                  <div className="modal-body share-content">
                    <label className="label-no-transform" htmlFor="email">
                      * E-mail this information to:
                    </label>
                    <input
                      id="email"
                      className="input share-address"
                      name="email"
                      placeholder="List of comma-separated email addresses"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <label className="label-no-transform optional">Optional information:</label>
                    <input
                      className="input share-client"
                      name="client"
                      placeholder="Name"
                      value={client}
                      onChange={(e) => setClient(e.target.value)}
                    />

                    <textarea
                      className="input share-comments"
                      name="comments"
                      placeholder="Comments and contact information"
                      rows={5}
                      wrap="hard"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />

                    {user && (
                      <p>
                        <input
                          id={`reply-to-me-${programSiteId}`}
                          className="input"
                          name="reply_to_me"
                          type="checkbox"
                          checked={reply_to_me}
                          onChange={() => toggleReply(!reply_to_me)}
                        />
                        <label htmlFor={`reply-to-me-${programSiteId}`}>&nbsp;Send replies to me</label>
                      </p>
                    )}

                    <button className="btn btn-primary" name="send" onClick={shareResourceList}>
                      Send e-mail
                    </button>
                  </div>
                </div>

                <div id={`share-text-${list.hashid}`} className="tab-pane" role="tabpanel">
                  <div className="modal-body share-content">
                    <label className="label-no-transform" htmlFor="phone">
                      * Text this information to:
                    </label>
                    <input
                      id="phone"
                      className="input share-phone"
                      name="phone"
                      placeholder="Mobile phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />

                    <p style={{ padding: "1em 0" }}>
                      <button className="btn btn-primary" name="send" onClick={shareResourceList}>
                        Send text message
                      </button>
                    </p>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn btn-default" data-dismiss="modal" type="button" onClick={onModalClose}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

ShareResourceList.propTypes = {
  list: PropTypes.object.isRequired,
  hidePrintFeature: PropTypes.bool,
  programSiteId: PropTypes.string,
  modalDisplayed: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
};
