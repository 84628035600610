import classNames from "classnames";
import React from "react";
import { ContentBlockData } from "./types";

function ContentBlock({ title, subtitle, button, image, className }: ContentBlockData) {
  return (
    <section className={classNames("content-block", "shaded-block", className)}>
      <div>
        <h2 className="content-block-title">{title}</h2>
        {subtitle && <p className="content-block-subtitle">{subtitle}</p>}
        <a className="content-block-button" target="_blank" rel="noreferrer" href={button.url}>
          {button.name}
        </a>
      </div>
      <div className="image-container">
        <img {...image} />
      </div>
    </section>
  );
}
export default ContentBlock;
