import React from "react";
import PropTypes from "prop-types";
import ReactUtils from "./../../react-utils";
import Dropdown from "../Dropdown";
import UpdatesRows from "./UpdatesRows";

const { useState } = React;

export default function UpdatesTable({ updates = [], sortByResource = false }) {
  const [filters, setFilters] = useState({ requestStatus: "all", type: "all" });
  const currentUpdateList = ReactUtils.filterBy(updates, filters);

  const sortDateItems = [{ "Most recent": "createdAt-desc" }, { "Oldest first": "createdAt" }];
  const [dateSortSelection, setDateSortSelection] = useState("Most recent");

  const statusItems = [
    { "View all": "all" },
    { Accepted: "accepted" },
    { "Partially accepted": "partially accepted" },
    { Denied: "denied" },
    { Pending: "pending" },
  ];
  const [statusSelectedItem, setStatusSelectedItem] = useState(Object.keys(statusItems[0])[0]);

  const resourceItems = [
    { "View all": "all" },
    { Agency: "agency" },
    { Site: "site" },
    { Service: "service" },
    { "Service Site Relationship": "service_site_relationship" },
  ];
  const [resourceSelectedItem, setResourceSelectedItem] = useState(Object.keys(resourceItems[0])[0]);

  if (updates.length) {
    return (
      <React.Fragment>
        <div className="agency-note">
          Note: This list only contains changes that were requested through this app &mdash; changes requested elsewhere
          will not appear here.
        </div>

        <div className="table-row table-header-row">
          <h3 className="agency-table-header">Change History</h3>
          <div className={`referrals-row dropdowns-container ${sortByResource && "many-dropdowns"}`}>
            {sortByResource && (
              <div className="dropdown-container">
                <div className="dropdown-label">Resource:</div>
                <Dropdown
                  items={resourceItems}
                  handleItemSelect={(item) => {
                    const name = Object.keys(item)[0];
                    setResourceSelectedItem(name);
                    setFilters({ ...filters, type: item[name] });
                  }}
                  id="status"
                  selected={resourceSelectedItem}
                />
              </div>
            )}
            <div className="dropdown-container">
              <div className="dropdown-label">Date:</div>
              <Dropdown
                items={sortDateItems}
                handleItemSelect={(item) => {
                  const name = Object.keys(item)[0];
                  setDateSortSelection(name);
                  ReactUtils.sortBy(item[name], updates);
                }}
                id="sort"
                selected={dateSortSelection}
              />
            </div>
            <div className="dropdown-container">
              <div className="dropdown-label">Status:</div>
              <Dropdown
                items={statusItems}
                handleItemSelect={(item) => {
                  const name = Object.keys(item)[0];
                  setStatusSelectedItem(name);
                  setFilters({ ...filters, requestStatus: item[name] });
                }}
                id="status"
                selected={statusSelectedItem}
                rightAlign={true}
              />
            </div>
          </div>
        </div>
        <div className="table-container agency-table">
          <table>
            <tbody>
              <UpdatesRows updates={currentUpdateList} />
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
  return null;
}

UpdatesTable.propTypes = {
  updates: PropTypes.array,
};
