import React from "react";
import AccountNavBar from "../AccountNavBar";
import ScreenerListItem from "./ScreenerListItem";

export default class ScreenerList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { responseSets } = this.props;

    return (
      <div className="screeners-layout">
        <div className="resource-lists-container row">
          <div className="resource-list-content">
            <AccountNavBar selection="screeners" />
            <div>
              <div className="resource-lists-title">
                <h1 className="lists-index">
                  {responseSets.length || "No"} Screener
                  {responseSets.length != 1 && "s"}
                </h1>
              </div>
            </div>
            <div className="screeners-region">
              <div className="screeners">
                {0 == responseSets.length && (
                  <p class="table-container">
                    Screeners can help you identify helpful resources and then save a list of them for later. There is a{" "}
                    <a href="/surveys">list of available screeners here</a>.
                  </p>
                )}
                {responseSets.map((responseSet) => (
                  <ScreenerListItem responseSet={responseSet} key={responseSet.token} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ScreenerList.propTypes = {
  responseSets: PropTypes.array,
};
