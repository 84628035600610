import React from "react";
import PropTypes from "prop-types";
import AgencyConfirmationModal from "./AgencyConfirmationModal";

const { useState, useEffect, Fragment } = React;

export default function RelationList({ id, listType, addRelationList }) {
  const [list, setList] = useState(null);
  const [error, setError] = useState(null);
  const [addingRelation, setAddingRelation] = useState(false);
  const [relationToAdd, setRelationToAdd] = useState(null);
  const [relationToConfirm, setRelationToConfirm] = useState(null);

  const type = listType.split("_")[0];
  const typeSingular = type.slice(0, -1);

  useEffect(() => {
    fetch(`${id}/${listType}`)
      .then((res) => res.json())
      .then((res) => setList(res))
      .catch((err) => {
        setError(`We're sorry, something went wrong while retrieving the list of ${listType}. Please try again later.`);
        console.error(`Error fetching ${listType} for ${id}: `, err);
      });
  }, []);

  const buildData = (itemId, action) => {
    const type = listType === "sites" ? "site_for_program" : "program_at_site";

    let data = {
      [action + "_" + type]: true,
      program_id: listType === "sites" ? id : itemId,
      site_id: listType === "sites" ? itemId : id,
    };

    return data;
  };

  const submitRelation = (itemId, action) => {
    const data = buildData(itemId, action);
    $.ajax("/program_site_relationship_updates", {
      method: "post",
      data: JSON.stringify(data),
      dataType: "json",
      contentType: "application/json",
    })
      .done((res) => (window.location = `/my211/updates/service_site_relationship_updates/${res.id}`))
      .fail((e) => {
        // TODO: Add a retry modal if this fails
        console.error(e);
      });
  };

  const getAddRelationContent = () => {
    if (addingRelation) {
      return (
        <div className="change-field-container">
          <div className="agency-change-input relation-input">
            <Dropdown
              items={addRelationList.map((relation) => relation.name)}
              handleItemSelect={(selection) => {
                const relation = addRelationList.find((relation) => relation.name === selection);
                setRelationToAdd(relation);
              }}
              id={`${type}-select`}
              selected={relationToAdd ? relationToAdd.name : `Select an existing ${type.slice(0, -1)}`}
            />
          </div>
          <div className="change-field-container button-container">
            <button
              className="agency-button-link"
              onClick={() => submitRelation(relationToAdd.number, "add")}
              disabled={!relationToAdd}
            >
              Save
            </button>
            <button className="agency-button-link cancel" onClick={() => setAddingRelation(false)}>
              Cancel
            </button>
          </div>
        </div>
      );
    } else if (addRelationList.length) {
      return (
        <button className="agency-relation-link" onClick={() => setAddingRelation(true)}>
          Link an existing {typeSingular}
        </button>
      );
    }
  };

  const getContent = () => {
    if (error) {
      return <div>{error}</div>;
    } else if (list) {
      return (
        <Fragment>
          {list.map((item) => (
            <div key={item.number} className="agency-item-row">
              <a href={`/my211/agency_${type}/${item.number}`}>
                {typeSingular === "service"
                  ? item.name || <em>No name provided</em>
                  : item.address_1 || <em>No street address provided</em>}
              </a>
              <button onClick={() => setRelationToConfirm(item)}>Remove {typeSingular}</button>
            </div>
          ))}
          {getAddRelationContent()}
          {App.org.links && App.org.links.claimResource && (
            <div className="agency-add-relation">
              Need to add a {typeSingular}?{" "}
              <a href={type === "sites" ? App.org.links.addSite : App.org.links.addService} target="_blank">
                Use this form.
              </a>
            </div>
          )}
        </Fragment>
      );
    } else {
      return <div className="data-loading">Loading...</div>;
    }
  };

  return (
    <div className="agency-container relationship-container">
      <h4 className="agency-label">
        {type} offered {type === "sites" ? "for this service" : "at this site"}
      </h4>
      {getContent()}
      {relationToConfirm && (
        <AgencyConfirmationModal
          type={typeSingular}
          item={relationToConfirm}
          setRelationToConfirm={setRelationToConfirm}
          submitRelation={submitRelation}
        />
      )}
    </div>
  );
}

RelationList.propTypes = {
  id: PropTypes.number.isRequired,
  listType: PropTypes.oneOf(["services", "sites"]).isRequired,
  addRelationList: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};
