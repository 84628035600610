import React from "react";
import SendReferralModal from "../SendReferralModal";

const { useState } = React;

export default function SendReferral(props) {
  const { program_site_id, name, agency_name } = props.model.attributes;
  const user = props.user && props.user.attributes;
  const { clicked } = props;

  const [openModal, setOpenModal] = useState(
    (Number(sessionStorage.getItem("program_site_id")) === program_site_id && user) || (clicked && user)
  );

  React.useEffect(() => {
    clicked && sendReferral();
  }, []);

  const sendReferral = () => {
    if (user) {
      setOpenModal(true);
    } else {
      if (App.Util.isSearchDetailPage()) sessionStorage.setItem("lastSearchUrl", `/search/${program_site_id}`);

      sessionStorage.setItem("program_site_id", program_site_id);
      window.location = "/referral/login";
    }
  };

  const closeModal = () => {
    App.vent.trigger("modal:close");
    if (Number(sessionStorage.getItem("program_site_id")) === program_site_id)
      sessionStorage.removeItem("program_site_id");
    setOpenModal(false);
  };

  return (
    <div>
      {App.Util.isSearchDetailPage() && (
        <div className="information-table">
          <h4 className="label referral-label">Service Requests</h4>
          <div>
            You can request services from this agency by sending a referral. When the agency responds, you will be
            notified by email and in your 211 account.
          </div>
        </div>
      )}

      <div
        onClick={() => sendReferral()}
        id="send-referral-btn"
        className={`referrals-row send-referral ${App.Util.isSearchDetailPage() && "btn btn-primary more"}`}
      >
        <div className={`ico ico-send-referral ${App.Util.isSearchDetailPage() && "ico-send-referral-white"}`}></div>
        <div className="send-referral-button-text">Request Services</div>
      </div>

      {openModal && (
        <SendReferralModal
          user={user}
          programSiteId={program_site_id}
          programName={name}
          agencyName={agency_name}
          toggleModalState={() => closeModal()}
        />
      )}
    </div>
  );
}
